import {Container} from 'react-bootstrap';

import LendAssets  from '../dashboard/components/LendAssetsV2';
import WalletBalances from '../WalletBalances';

export default function Lend(props) {
    return <Container>
        <WalletBalances />
        <LendAssets {...props}/>
    </Container>
}

