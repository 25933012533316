import {Container, Row, Col} from 'react-bootstrap';

import WalletBalances from '../WalletBalances';
import Transactions from './components/Transactions';
import Snapshot from './components/Snapshot';
import { generateTimeline } from './components/calc';
import { connect } from 'react-redux';
import OpenPositions from './components/OpenPositions';
import ClosedPositions from './components/ClosedPositions';
import ConnectWallet from '../../components/ConnectWallet';

function Portfolio (props) {
    if(!props.connected) {
        return   <ConnectWallet
            onConnect = {() => props.onConnect()}
            themeMode={props.themeMode}
        />
    }
    const {borrowData} = generateTimeline(props.assets, props.transactions, props.accountSnapshot);
    return <Container>
        <WalletBalances />
        <Snapshot/>
        <OpenPositions
            data={borrowData}
            assets={props.assets}
            accountSnapshot={props.accountSnapshot}
        />
        <ClosedPositions
            data={borrowData}
            assets={props.assets}
        />
        <Transactions/>
    </Container>
}

const mapStateToProps = state => {
    return {
        connected: state.get("connected"),
        assets: state.get("assets"),
        accountSnapshot: state.get("accountSnapshot"),
        transactions: state.getIn(["transactions", "data"]),
        themeMode: state.get("themeMode")
    }
}


export default connect(mapStateToProps, null)(Portfolio)
