export const chains = [
    {
        chainId : 1,
        name: "ethereum",
        rpc: "https://eth.llamarpc.com",
    },
    {
        chainId : 56,
        name: "bnb",
        rpc : "https://bscrpc.com/"
    },
    {
        chainId : 43114,
        name: "avalanche",
        rpc : "https://api.avax.network/ext/bc/C/rpc"
    },
    {
        chainId : 137,
        name: "polygon",
        rpc : "https://polygon-rpc.com/"
    },
    {
        chainId : 42161,
        name: "arbitrum",
        rpc : "https://arb1.arbitrum.io/rpc"
    }
]

export const tokens = [
    {
        symbol: "usdt",
        decimal: 6,
        oraclePrice: "100000000",
        "1" : "0xdAC17F958D2ee523a2206206994597C13D831ec7", // uni verified
        "56" : "0x0a70dDf7cDBa3E8b6277C9DDcAf2185e8B6f539f",
        "43114" : "0xc7198437980c041c805A1EDcbA50c1Ce5db95118",
        "137" : "0xc2132D05D31c914a87C6611C10748AEb04B58e8F", // verified
        "42161" : "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9" //uni verified
    },
    {
        symbol: "usdc",
        decimal: 6,
        oraclePrice: "100000000",
        "1" : "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48", // uni verified
        "56" : "0x8965349fb649A33a30cbFDa057D8eC2C48AbE2A2",
        "43114" : "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
        "137" : "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174", // verified
        "42161" : "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8" // uni verified
    },
    {
        symbol: "weth",
        decimal: 18,
        oraclePrice: "186897000000",
        "1" : "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", //weth
        "56" : "0x4DB5a66E937A9F4473fA95b1cAF1d1E1D62E29EA",
        "43114" : "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB", //weth
        "137" : "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619", // verified
        "42161" : "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1" // no weth on uniswap
    }
]

export const rpc = {
    "1" : "https://mainnet.infura.io/v3/",
    "56" : "https://bscrpc.com/",
    "43114" : "https://api.avax.network/ext/bc/C/rpc",
    "137" : "https://polygon-rpc.com/",
    "42161" : "https://arb1.arbitrum.io/rpc"
}