import { decimal2Fixed, getContract, getGasPrice } from "../utils";
import { pTokenABI } from "../utils/abi";

export const updateAccount = (data) => {
	return {
		type: "UPDT_ACCOUNT",
		data
	}
}

const requestAccountSnapshot = data => {
	return {
		type: "REQ_ACC_SNAPSHOT"
	}
}

const receiveAccountSnapshot = (error, data) => {
	return {
		type: "REC_ACC_SNAPSHOT",
		error, data
	}
}

export const fetchAccountSnapshot = (contract, accAddress, tokens) => {
	return dispatch => {
		dispatch(fetchWeFiPrice());
		dispatch(requestAccountSnapshot());
		contract.methods.multiGetAccountSnapshot(tokens, accAddress)
        .call((error, result) => {
			// console.log("Snapshot Response", result);
			if(error) {
				console.log("Snapshot error", error);
			} else {
				dispatch(receiveAccountSnapshot(error, result));
			}
		});
	}	
}


const requestNativeBalance = () => {
	return {
		type: "REQ_NATIVE_BAL"
	}
}

export const setNativeBalance = (balance) => {
	return {
		type: "REC_NATIVE_BAL",
		balance
	}
}

export const fetchNativeBalance = (web3, accAddress) => {
	return dispatch => {
		dispatch(requestNativeBalance());
		web3.eth.getBalance(accAddress)
		.then((res)=> {
			dispatch(setNativeBalance(Number(res)));
		});
	}	
}

const requestFetchEstimate = (data) => {
	return {
		type: "REQ_ESTIMATE",
		data
	}
}
const receiveEstimate = (data, error, result) => {
	return {
		type: "REC_ESTIMATE",
		data,
		error,
		result
	}
}

export const fetchEstimate = (contract, data) => {
	// console.log("Estimate Payload", data);
	return dispatch => {
		dispatch(requestFetchEstimate(data));
		if (data.in) {
			contract.methods.getEstimateAmountsIn(
				data.uniswapQuoter,
				data.borrowToken,
				data.decimalValue,
				data.targetToken,
				data.fee
			)
			.call((error, result) => {
				dispatch(receiveEstimate(data, error, result));
			});
		} else {
			contract.methods.getEstimateAmountsOut(
				data.uniswapQuoter,
				data.borrowToken,
				data.decimalValue,
				data.targetToken,
				data.fee
			)
			.call((error, result) => {
				dispatch(receiveEstimate(data, error, result));
			});
		}
		
	}	
}

const receiveTotalCash = (address, result) => {
	return {
		type: "REC_TOTAL_CASH",
		address,
		result
	}
}

export const fetchTotalCash = (contract, address) => {
	return dispatch => {
		contract.methods.getCash()
		.call((error, result) => {
			// console.log("total cash result", result, error);
			dispatch(receiveTotalCash(address, result));
		});
	}
}

const requestTransaction = (txDesc) => {
	return {
		type: "REQ_TRANS",
		txDesc
	}
}

const updateTransactionId = (txId) => {
	return {	
		type: "UPD_TRANS_ID",
		txId
	}
}

const receiveTransaction = (success, response) => {
	return {
		"type": "REC_TRANS",
		success,
		response
	}
}

export const resetTransaction = () => {
	return {
		"type": "RESET_TRANS"
	}
}

const checkTransaction = (web3, txId) => {
	return dispatch => {
		// console.log("Checking Transaction", txId);
		web3.eth.getTransactionReceipt(txId, (err, response) => {
			// console.log("Check TXN ", response);
			if (response) {
				// console.log("Calling recieved trans");
				dispatch(receiveTransaction(true, response));
			} else {
				setTimeout(()=> {
					dispatch(checkTransaction(web3, txId));
				}, 3000)
			}
		})
	}
}

export const supplyTokens = (web3, contract, accAddress, amount, data, gasType) => {
	return async dispatch => {
		dispatch(requestTransaction(data.txDesc));
		const gasPrice = await getGasPrice(gasType);
		contract.methods.mint(amount)
		.send({from: accAddress, ...gasPrice})
		.on("transactionHash", (txId) => {
			// console.log("Transaction success", txId);
			dispatch(updateTransactionId(txId));
			dispatch(checkTransaction(web3, txId));
		})
		.on("error", function (error) {
			// console.log("Transaction Error", error);
			dispatch(updateTransactionId(null));
			dispatch(receiveTransaction(false, null));
		})
	}
}

export const borrowAndBuy = (web3, contract, accAddress, data, gasType) => {
	return async dispatch => {
		dispatch(requestTransaction("borrow"));
		const gasPrice = await getGasPrice(gasType);
		contract.methods.borrowAndBuy(data.targetAddress, data.borrowAmount, data.partialAmount, data.targetAmount, data.dexData)
		.send({ from: accAddress, ...gasPrice })
		.on("transactionHash", (txId) => {
			// console.log("Transaction success", txId);
			dispatch(updateTransactionId(txId));
			dispatch(checkTransaction(web3, txId));
		})
		.on("error", function (error) {
			// console.log("Transaction Error", error);
			dispatch(updateTransactionId(null));
			dispatch(receiveTransaction(false, null));
		})
	}
}

export const repayBorrow = (web3, contract, accAddress, amount, gasType) => {
	return async dispatch => {
		dispatch(requestTransaction("repay"));
		const gasPrice = await getGasPrice(gasType);
		contract.methods.repayBorrow(amount)
		.send({ from: accAddress, ...gasPrice})
		.on("transactionHash", (txId) => {
			// console.log("Transaction success", txId);
			dispatch(updateTransactionId(txId));
			dispatch(checkTransaction(web3, txId));
		})
		.on("error", function (error) {
			// console.log("Transaction Error", error);
			dispatch(updateTransactionId(null));
			dispatch(receiveTransaction(false, null));
		})
	}
}

export const redeemTokens = (web3, contract, accAddress, tokens, gasType) => {
	return async dispatch => {
		dispatch(requestTransaction("redeem"));
		const gasPrice = await getGasPrice(gasType);
		contract.methods.redeem(tokens)
		.send({ from: accAddress, ...gasPrice})
		.on("transactionHash", (txId) => {
			// console.log("Transaction success", txId);
			dispatch(updateTransactionId(txId));
			dispatch(checkTransaction(web3, txId));
		})
		.on("error", function (error) {
			// console.log("Transaction sError", error);
			dispatch(updateTransactionId(null));
			dispatch(receiveTransaction(false, null));
		})
	}
}


export const toggleTheme = mode => {
	return {
		"type": "TOGGLE_THEME",
		mode
	}
}

const setAccessStatus = status => {
	return {
		"type": "SET_ACCESS_STATUS",
		status
	}
}


export const fetchWhiteLabelAccess = address => {
	return dispatch => {
		fetch(`https://hnvv93zhmi.execute-api.ap-south-1.amazonaws.com/default/whitelist/acc-status/${address}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
			// body: JSON.stringify({}),
		}).then((res)=> res.json()).then((response) => {
			dispatch(setAccessStatus(response.status));
			// dispatch(setAccessStatus(3));
		});
	}
}


const topggleReqTransactions = (status) => {
	return {
		type: "TOGGLE_TRANS_DATA",
		status
	}
}

const recTransactions = data => {
	return {
		type: "REC_TRANS_DATA",
		data
	}
}

export const fetchTransactions = (network, address) => {
	return dispatch => {
		dispatch(topggleReqTransactions(true));
		fetch(`https://ayn1ewl5ck.execute-api.ap-south-1.amazonaws.com/${network}/transactions/${address}`, {
			method: 'GET',
		}).then((res)=> res.json()).then((response) => {
			if (response.success) {
				dispatch(recTransactions(response.response.data.userEvents));
			} else {
				throw("error in transactions request.");
			}
		}).catch((err)=> {
			console.log(err);
		}).finally(()=> {
			dispatch(topggleReqTransactions(false));
		});
	}
}


export const saveGasPreference = pref => {
	return {
		type: "SAVE_GAS_PREF",
		pref
	}
}

const toggleReqGasPrices = status => {
	return {
		type: "TOGGLE_REQ_GAS_PRICES",
		status
	}
}

const recGasPrices = data => {
	return {
		type: "REC_GAS_PRICES",
		data
	}
}

export const fetchGasPrices = () => {
	return dispatch => {
		dispatch(toggleReqGasPrices(true));
		fetch('https://gasstation.polygon.technology', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((res)=> res.json()).then((response) => {
			dispatch(recGasPrices(response));
		}).catch((err)=> {
			console.log("Error in gas price api", err);
		}).finally(()=> {
			dispatch(toggleReqGasPrices(false));
		});
	}
}

export const setActivePoolFee = fee => {
	return {
		type: "SET_ACTIVE_POOL_FEE",
		fee
	}
}


export const swapAndSettle = (web3, contract, accAddress, data, gasType ) => {
	// console.log("Swap and settle data", data);
	return async dispatch => {
		dispatch(requestTransaction("settle"));
		const gasPrice = await getGasPrice(gasType);
		contract.methods.swapAndSettle(
			data.numTokens,
			data.borrowedAsset,
			data.borrowedAssetAmount,
			data.dexData)
		.send({ from: accAddress, ...gasPrice })
		.on("transactionHash", (txId) => {
			// console.log("Transaction success", txId);
			dispatch(updateTransactionId(txId));
			dispatch(checkTransaction(web3, txId));
		})
		.on("error", function (error) {
			// console.log("Transaction sError", error);
			dispatch(updateTransactionId(null));
			dispatch(receiveTransaction(false, null));
		})
	}
}

const toggleWhitelistReq = status => {
	return {
		type: "TOGGLE_WHITELIST_REQ",
		status
	}
}


export const doWhiteList = address => {
	return dispatch => {
		dispatch(toggleWhitelistReq(true));
		// setTimeout(()=> {
		// 	dispatch(setAccessStatus(2));
		// 	dispatch(toggleWhitelistReq(false));
		// }, 3000);
		fetch(`https://hnvv93zhmi.execute-api.ap-south-1.amazonaws.com/default/whitelist/process`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({"address": address}),
		}).then((res)=> res.json()).then((response) => {
			if(response.status === 1) {
				setTimeout(()=> {
					dispatch(setAccessStatus(response.status));
					dispatch(toggleWhitelistReq(false));
				}, 8000);
			} else {
				dispatch(setAccessStatus(response.status));
				dispatch(toggleWhitelistReq(false));
			}
		}).catch((err)=> {
			dispatch(setAccessStatus(2));
			dispatch(toggleWhitelistReq(false));
		});
	}
}

const recHistoricData = (data, days) => {
	return {
		type: "REC_HIST_DATA",
		data,
		days
	}
}

export const fetchHistoricData = (chainId, days) => {
	return async dispatch => {
		fetch(`https://fzfs6v8glb.execute-api.ap-south-1.amazonaws.com/default/${chainId === 80001 ? "mumbai-short": "matic"}/historic/${days}`, {
			method: 'GET'
		}).then((res)=> res.json()).then((response) => {
			if (response.success) {
				dispatch(recHistoricData(response.response, days));
			} else {
				throw("error in transactions request.");
			}
		}).catch((err)=> {
			console.log(err);
		}).finally(()=> {
			dispatch(topggleReqTransactions(false));
		});
	}	
}

const topggleReqNftData = (status) => {

	return {
		type: "TOGGLE_NFT_DATA",
		status
	}
}

const recNftData = data => {

	return {
		type: "REC_NFT_DATA",
		data
	}
}

export const fetchNftData = (contract, address, chainId) => {
	return dispatch => {
		dispatch(topggleReqNftData(true));
		fetch(`https://guvnezk9sf.execute-api.ap-south-1.amazonaws.com/nft-rewards/${chainId === 80001 ? "mumbai": "polygon"}/${address}`, {
			method: 'GET',
		}).then((res)=> res.json()).then((response) => {
			if(response.success) {
				dispatch(recNftData(response.response));
				dispatch(getMintedNfts(contract, address,  response.response.map((item)=> item.tier.id)))
			}
		}).catch((err)=> {
			console.log("caught in error.....", err);
		}).finally(()=> {
			dispatch(topggleReqNftData(false));
		});
		// setTimeout(()=> {}, 3000);
		// const response = [];
		// dispatch(recNftData(response));
		// dispatch(topggleReqNftData(false));
		// dispatch(getMintedNfts(contract, address,  response.map((item)=> item.tier.id)))
	}
}

const topggleReqMintedData = (status) => {
	return {
		type: "TOGGLE_MINT_NFT_DATA",
		status
	}
}

const recNftMintedData = data => {

	return {
		type: "REC_MINT_NFT_DATA",
		data
	}
}

const getMintedNfts =  (contract, account, tierIds) => {
	return async dispatch => {
		dispatch(topggleReqMintedData(true));
		const mintedIds = [];
		for(let i =0; i<tierIds.length; i++) {
			const minted = await contract.methods.alreadyMinted(account, tierIds[i]).call();
			if (minted) {
				mintedIds.push(tierIds[i]);
			}
		}
		dispatch(recNftMintedData(mintedIds));
		dispatch(topggleReqMintedData(false));
	}	
}


export const mintNft = (web3, contract, accAddress, tierId, proof, nftData, gasType) => {
	return async dispatch => {
		const gasPrice = await getGasPrice(gasType);

		dispatch(requestTransaction("mintNft"));
		contract.methods.mintNFT(tierId, proof)
		.send({ from: accAddress, ...gasPrice })
		.on("transactionHash", (txId) => {
			dispatch(updateTransactionId(txId));
			dispatch(checkTransaction(web3, txId)); // 
			// const nftDataToSend = nftData.toJS();
			// dispatch(getMintedNfts(contract, accAddress, nftDataToSend.map((item)=> item.tier.id)))
		}).on("confirmation",(confNumber) => {
			if(confNumber === 2) {
				const nftDataToSend = nftData.toJS();
				dispatch(getMintedNfts(contract, accAddress, nftDataToSend.map((item)=> item.tier.id)))
			}
		})
		.on("error", function (error) {
			console.log("Transaction Error", error);
			dispatch(updateTransactionId(null));
			dispatch(receiveTransaction(false, null));
		})
	}
}

const toggleReqLeaderboard = status => {
	return {
		type: "TOGGLE_LEADERBD",
		status
	}
}

const recLeaderboard = data => {
	return {
		type: "REC_LEADERBD",
		data
	}
}

export const fetchLeaderboard = () => {
	return dispatch => {
		dispatch(toggleReqLeaderboard(true));
		fetch(`https://5zgbtth712.execute-api.ap-south-1.amazonaws.com/default/trading-comp`, {
			method: 'GET',
		}).then((res)=> res.json()).then((response) => {
			if(response.success) {
				dispatch(recLeaderboard(response.response));
			}
		}).catch((err)=> {
			console.log("caught in error.....", err);
		}).finally(()=> {
			dispatch(toggleReqLeaderboard(false));
		});
	}
}

const receiveAssetsIn = (address, result) => {
	return {
		type: "REC_ASSETS_IN",
		address,
		result
	}
}

export const fetchAssetsIn = (contract, address) => {
	return dispatch => {
		contract.methods.getAssetsIn(address)
		.call((error, result) => {
			dispatch(receiveAssetsIn(address, result));
		});
	}
}

const recWefiPrice = price => {
	return {
		type: "REC_WEFI_PRICE",
		price: price
	}
}

export const fetchWeFiPrice = () => {
	return dispatch => {
		fetch(`https://coins.llama.fi/prices/current/polygon:0xffa188493c15dfaf2c206c97d8633377847b6a52`, {
			method: 'GET',
		}).then((res)=> res.json()).then((response) => {
			if(response.coins && response.coins["polygon:0xffa188493c15dfaf2c206c97d8633377847b6a52"]) {
				dispatch(recWefiPrice(response.coins["polygon:0xffa188493c15dfaf2c206c97d8633377847b6a52"].price));
			} else {
				console.log("Could not find wefi price in api response");
			}
		}).catch((err)=> {
			console.log("wefi price fetch error.....", err);
		})
	}
}