import { decimal2Fixed } from "../utils";

export const transaction = {
    isActive: false,
    isLoading: false,
    isCompleted: false
};

const polygonMainnetAssets = [
    {
        "name": "WeFi Token Vault",
        "symbol": "WEFI",
        "address": "0xB50FF38F0fB98Dd5f9FB76ea333927BeB8F1920c",
        "uAddress": "0xfFA188493C15DfAf2C206c97D8633377847b6a52",
        "decimals": 18,
        "lendEnabled": true,
        "borrowEnabled": false,
        "buyEnabled": false,
        "buyAssets": []
    },
    {
        "name": "USDC",
        "symbol": "USDC",
        "address": "0x34Aa3Fb5F02df5FBD03545ED3b35b90E51eEE88D",
        "uAddress": "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
        "decimals": 6,
        "lendEnabled": true,
        "borrowEnabled": true,
        "buyEnabled": false,
        "isStable": true,
        "buyAssets": ["0x2588c9214376dDb524DCfa72859B522E745283Bf", "0xd5700c677Ddc823B0b6cC9a5Dc8415C07842Ef35", "0x8f2DaDC86e7441B613ba1ab6A42193b1930E15ac"]
    },
    {
        "name": "Tether USD",
        "symbol": "USDT",
        "address": "0x78718e0cADA1CA39fA23FC710e4accfd0FF9a5E9",
        "uAddress": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
        "decimals": 6,
        "lendEnabled": true,
        "borrowEnabled": true,
        "buyEnabled": false,
        "isStable": true,
        "buyAssets": ["0x2588c9214376dDb524DCfa72859B522E745283Bf", "0xd5700c677Ddc823B0b6cC9a5Dc8415C07842Ef35"]
    },
    {
        "name": "Wrapped Matic",
        "symbol": "WMATIC",
        "address": "0x2588c9214376dDb524DCfa72859B522E745283Bf",
        "uAddress": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
        "decimals": 18,
        "lendEnabled": false,
        "borrowEnabled": false,
        "buyEnabled": true,
        "borrowAssets": ["0x34Aa3Fb5F02df5FBD03545ED3b35b90E51eEE88D", "0x78718e0cADA1CA39fA23FC710e4accfd0FF9a5E9"]
    },
    {
        "name": "WETH",
        "symbol": "WETH",
        "address": "0xd5700c677Ddc823B0b6cC9a5Dc8415C07842Ef35",
        "uAddress": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
        "decimals": 18,
        "lendEnabled": false,
        "borrowEnabled": false,
        "buyEnabled": true,
        "borrowAssets": ["0x34Aa3Fb5F02df5FBD03545ED3b35b90E51eEE88D", "0x78718e0cADA1CA39fA23FC710e4accfd0FF9a5E9"]
    },
    {
        "name": "Wrapped BTC",
        "symbol": "WBTC",
        "address": "0x8f2DaDC86e7441B613ba1ab6A42193b1930E15ac",
        "uAddress": "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
        "decimals": 8,
        "lendEnabled": false,
        "borrowEnabled": false,
        "buyEnabled": true,
        "borrowAssets": ["0x34Aa3Fb5F02df5FBD03545ED3b35b90E51eEE88D"]
    },
    {
        "name": "Aave",
        "symbol": "AAVE",
        "address": "0x6dC71aC27CEE3eaAE4765258C29C427687974002",
        "uAddress": "0xd6df932a45c0f255f85145f286ea0b292b21c90b",
        "decimals": 18,
        "lendEnabled": false,
        "borrowEnabled": false,
        "buyEnabled": true,
        "borrowAssets": ["0x34Aa3Fb5F02df5FBD03545ED3b35b90E51eEE88D", "0x78718e0cADA1CA39fA23FC710e4accfd0FF9a5E9", "0x1e8Cd6843Db6286F4c7cF86EFDA572ECcC4DFF05"]
    },
    {
        "name": "Quickswap",
        "symbol": "QUICK",
        "address": "0x2a500d5707F8FbBaeD35b99867AF94DfE86F36D6",
        "uAddress": "0x831753DD7087CaC61aB5644b308642cc1c33Dc13",
        "decimals": 18,
        "lendEnabled": false,
        "borrowEnabled": false,
        "buyEnabled": true,
        "borrowAssets": ["0x34Aa3Fb5F02df5FBD03545ED3b35b90E51eEE88D", "0x78718e0cADA1CA39fA23FC710e4accfd0FF9a5E9"]
    },
     {
        "name": "ChainLink Token",
        "symbol": "LINK",
        "address": "0xD0B5A656Fb3Fa2B5CC61cB5F2aD7904C39D75D50",
        "uAddress": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
        "decimals": 18,
        "lendEnabled": false,
        "borrowEnabled": false,
        "buyEnabled": true,
        "borrowAssets": ["0x34Aa3Fb5F02df5FBD03545ED3b35b90E51eEE88D", "0x78718e0cADA1CA39fA23FC710e4accfd0FF9a5E9"]
    },
    {
        "name": "Uniswap",
        "symbol": "UNI",
        "address": "0x12F38c40D605e5Add1D5b92359B3be8157ABDB6b",
        "uAddress": "0xb33EaAd8d922B1083446DC23f610c2567fB5180f",
        "decimals": 18,
        "lendEnabled": false,
        "borrowEnabled": false,
        "buyEnabled": true,
        "borrowAssets": ["0x34Aa3Fb5F02df5FBD03545ED3b35b90E51eEE88D", "0x78718e0cADA1CA39fA23FC710e4accfd0FF9a5E9"]
    },
    {
        "name": "USDC Vault",
        "symbol": "USDC",
        "address": "0xef0b81a38a3e61c94D1c3F9D24c22FAa9772Fa95",
        "uAddress": "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
        "decimals": 6,
        "lendEnabled": false,
        "borrowEnabled": false,
        "buyEnabled": true,
        "isStable": true,
        "borrowAssets": ["0x1e8Cd6843Db6286F4c7cF86EFDA572ECcC4DFF05"]
    },
    {
        "name": "WETH LP",
        "symbol": "WETH",
        "address": "0x1e8Cd6843Db6286F4c7cF86EFDA572ECcC4DFF05",
        "uAddress": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
        "decimals": 18,
        "lendEnabled": true,
        "borrowEnabled": true,
        "buyEnabled": false,
        "buyAssets": ["0xef0b81a38a3e61c94D1c3F9D24c22FAa9772Fa95", "0x6dC71aC27CEE3eaAE4765258C29C427687974002"]
    }
];



const defaultState =  {
    themeMode: "dark",
    // network: "mumbai-testnet",
    network: "polygon",
    connected: false,
    networkId: null,
    chainId: null,
    accAddress: "",
    web3: null,
    isLoadingNativeBalance: false,
    nativeBalance: -1,
    dexes: [
        {"name": "UniswapV3", "id": "1", "quoterAddress": "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6"},
        {"name": "QuickSwap", "id": "2", "quoterAddress": "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff"}
    ],
    contracts: {
        libABI: "0x971a78F06d25C36DFacC5A598f81475Ad3bd8eb5",
        uniswapQuoter: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
        comptroller: "0x1eDf64B621F17dc45c82a65E1312E8df988A94D3",
        oracle: "0xe696dD106ad4C0e541De30a7820409dB95d1e7cF",
        nft: "0xf5820c137679E49F5AB06B5A9e79DCEA0E4a3b42"
    },
    supportedChains: [
        // {
        //     name: "Mumbai-Testnet (Polygon)",
        //     shortName: "",
        //     chain: "ETH",
        //     network: "",
        //     chainId: 80001,
        //     networkId: 80001,
        //     nativeCurrency: {
        //         symbol: "MATIC",
        //         name: "MATIC",
        //         decimals: "18",
        //         contractAddress: "",
        //         balance: ""
        //     },
        //     webURL: "https://mumbai.polygonscan.com"
        // },
        {
            name: "Polygon",
            shortName: "matic",
            chain: "ETH",
            network: "",
            chainId: 137,
            networkId: 137,
            nativeCurrency: {
                symbol: "MATIC",
                name: "MATIC",
                decimals: "18",
                contractAddress: "",
                balance: ""
            },
            webURL: "https://polygonscan.com"
        }
    ],
    assets: polygonMainnetAssets.map((asset)=> {
        asset.address = asset.address.toLowerCase();
        asset.uAddress = asset.uAddress.toLowerCase();
        if(asset.borrowAssets) {
            asset.borrowAssets = asset.borrowAssets.map((address)=> address.toLowerCase())
        }
        if(asset.buyAssets) {
            asset.buyAssets = asset.buyAssets.map((address)=> address.toLowerCase())
        }
        return asset;
    }),
    snapshot: { //not used
        isLoading: false
    },
    accountSnapshot: {
        isLoading: false
    },
    balanceAndAllowances: {
        isLoading: false,
        uTokens: {}
    },
    swapEstimates: {
    },
    totalUnderlyingCash: {

    },
    transaction,
    accessStatus: {
        processing: false,
        status: null
    },
    oraclePrices: null,
    transactions: {
        isLoading: false,
        data: []
    },
    gasPrices: {
        // SafeGasPrice - standard, ProposeGasPrice - fast , FastGasPrice - rapid
        active: "fast",
        data: {},
        isLoading: false
    },
    activePoolFee: "3000",
    portfolio: {
        accountLiquidity: 0,
        accountLiquidityDecimals: 0,
        compAccrued: 0
    },
    historicData: {},
    nftCollection: {
        isLoading: false,
        data: []
    },
    mintedCollection : {
        isLoading: false,
        data: []
    },
    leaderboard: {
        isLoading: false,
        data: null
    },
    assetsIn: {

    },
    wefiPrice: decimal2Fixed(0.3, 8)
}


// const mumbaiTestnetAssets = [
//     {
//         "name": "USD Coin",
//         "symbol": "USDC",
//         // "address": "0x57c5c55A295b0040e22a8E3FBFCE9d1d81860BB3",
//         "address": "0x54B1523c997D5C6B1667342fD7eA64D0aa485235",
//         "uAddress": "0xD33572f6DD1bb0D99C8397c8efE640Cf973EaF3B",
//         "decimals": 6,
//         "lendEnabled": true,
//         "borrowEnabled": true,
//         "buyEnabled": false,
//         "uniswapPoolFee": {
//             "0x86A37165df8bc9CA9715410d708F652FBfd353B4": "3000", //0.3
//             "0xAcDDeAD7A730862F4f0893FDBe62Eb749964C526": "3000" //0.3
//         }
//     },
//     {
//         "name": "WMATIC",
//         "symbol": "WMATIC",
//         // "address": "0xE952e7E780b1759b7CF35C36c63B2d3620Fd9369",
//         "address": "0x86A37165df8bc9CA9715410d708F652FBfd353B4",
//         "uAddress": "0x64659Fa9064Bb434daA6E8e0b2706D01e9f9e30F",
//         "decimals": 18,
//         "lendEnabled": false,
//         "borrowEnabled": false,
//         "buyEnabled": true
//     },
//     // {
//     //     "name": "AAVE",
//     //     "symbol": "AAVE",
//     //     "address": "0x1ECf0E8fDe5b68cA746dBE1E1989ac68B67571bc",
//     //     "uAddress": "0x7048f7a5D4781BB097Cb839171832e96e8aFa27f",
//     //     "decimals": 18,
//     //     "lendEnabled": false,
//     //     "borrowEnabled": false,
//     //     "buyEnabled": true
//     // },
//     {
//         "name": "WETH",
//         "symbol": "WETH",
//         // "address": "0x19ac5b578615450C8D3e50Adcc37Ae2C63822c90",
//         "address": "0xAcDDeAD7A730862F4f0893FDBe62Eb749964C526",
//         "uAddress": "0xe37f602e3dF0f7B2b0374EDd55F54272543442F1",
//         "decimals": 18,
//         "lendEnabled": false,
//         "borrowEnabled": false,
//         "buyEnabled": true
//     }

// ];

// const ropstenAssets =  [
//     {
//         "name": "USD Coin",
//         "symbol": "USDC",
//         "address": "0x82420C8f17D88a6BBa8dFd49a89D4808ca06Cb48",
//         "uAddress": "0x07865c6e87b9f70255377e024ace6630c1eaa37f",
//         "decimals": 6,
//         "lendEnabled": true,
//         "borrowEnabled": true,
//         "buyEnabled": false
//     },
//     {
//         "name": "WETH",
//         "symbol": "WETH",
//         "address": "0x6A8B2cDd084208d25Bc70f457FFE35E01338106b",
//         "uAddress": "0xc778417E063141139Fce010982780140Aa0cD5Ab",
//         "decimals": 18,
//         "lendEnabled": false,
//         "borrowEnabled": false,
//         "buyEnabled": true
//     },
//     {
//         "name": "WBTC",
//         "symbol": "WBTC",
//         "address": "0x4F8818fAc203E3481F86123483c139A025f648eE",
//         "uAddress": "0x442be68395613bdcd19778e761f03261ec46c06d",
//         "decimals": 8,
//         "lendEnabled": false,
//         "borrowEnabled": false,
//         "buyEnabled": true
//     }
// ]



export default defaultState;