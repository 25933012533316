import defaultState, { transaction } from './defaultState';
import { fromJS} from 'immutable';
import { decimal2Fixed, fixed2Decimals } from '../utils';
const WEFI_VAULT_ADDRESS = "0xB50FF38F0fB98Dd5f9FB76ea333927BeB8F1920c".toLowerCase();

export default function reducer(state = fromJS(defaultState), action) {
    switch (action.type) {
        case "TOGGLE_THEME":
            return state = state.set("themeMode", action.mode);
        case "UPDT_ACCOUNT":
            return updateAccountData(state, action.data);
        case "REQ_NATIVE_BAL":
            return state.set("isLoadingNativeBalance", true);
        case "REC_NATIVE_BAL":
            state = state.set("isLoadingNativeBalance", false);
            return state.set("nativeBalance", action.balance);
        case "REQ_ACC_SNAPSHOT":
            state = state.setIn(["balanceAndAllowances", "isLoading"], true);
            return state.setIn(["accountSnapshot", "isLoading"], true);
        case "REC_ACC_SNAPSHOT":
            state = processAccountSnapshot(state, action.data);
            state = state.setIn(["balanceAndAllowances", "isLoading"], false);
            return state.setIn(["accountSnapshot", "isLoading"], false);
        case "REQ_ESTIMATE":
            return state.setIn(["swapEstimates", action.data.targetToken, action.data.value, "isLoading"], true);
        case "REC_ESTIMATE":
            state = state.setIn(["swapEstimates", action.data.targetToken, action.data.value, "isLoading"], false);
            state = state.setIn(["swapEstimates", action.data.targetToken, action.data.value, "error"], action.error);
            return state.setIn(["swapEstimates", action.data.targetToken, action.data.value, "estimate"], action.result);
        case "REC_TOTAL_CASH":
            return state.setIn(["totalUnderlyingCash", action.address],  action.result);
        case "REQ_TRANS":
            state = state.set("transaction", fromJS({}));
            state = state.setIn(["transaction", "txDesc"], action.txDesc);
            state = state.setIn(["transaction", "isCompleted"], false);
            state = state.setIn(["transaction", "isActive"], true);
            return state.setIn(["transaction", "isLoading"], true);
        case "UPD_TRANS_ID":
            return state.setIn(["transaction", "txId"], fromJS(action.txId));
        case "REC_TRANS":
            state = state.setIn(["transaction", "response"], fromJS(action.response));
            state = state.setIn(["transaction", "isSuccess"], action.success);
            state = state.setIn(["transaction", "isCompleted"], true);
            return state.setIn(["transaction", "isLoading"], false);
        case "RESET_TRANS":
            return state.set("transaction", fromJS(transaction));
        case "SET_ACCESS_STATUS":
            return state.setIn(["accessStatus", "status"], action.status);
        case "TOGGLE_TRANS_DATA":
            return state.setIn(["transactions", "isLoading"], action.status);
        case "REC_TRANS_DATA":
            return state.setIn(["transactions", "data"], fromJS(action.data));
        case "SAVE_GAS_PREF":
            return state.setIn(["gasPrices", "active"], action.pref);
        case "TOGGLE_REQ_GAS_PRICES":
            return state.setIn(["gasPrices", "isLoading"], action.status);
        case "REC_GAS_PRICES":
            return state.setIn(["gasPrices", "data"], fromJS(action.data));
        case "SET_ACTIVE_POOL_FEE":
            return state.set("activePoolFee", action.fee);
        case "TOGGLE_WHITELIST_REQ":
            return state.setIn(["accessStatus", "processing"], action.status);
        case "REC_HIST_DATA":
            return state.setIn(["historicData", action.days.toString()], fromJS(action.data));
        case "TOGGLE_NFT_DATA":
            return state.setIn(["nftCollection", "isLoading"], action.status);
        case "REC_NFT_DATA":
            return state.setIn(["nftCollection", "data"], fromJS(action.data));
        case "TOGGLE_MINT_NFT_DATA":
            return state.setIn(["mintedCollection", "isLoading"], action.status);
        case "REC_MINT_NFT_DATA":
            return state.setIn(["mintedCollection", "data"], fromJS(action.data));
        case "TOGGLE_LEADERBD":
            return state.setIn(["leaderboard", "isLoading"], action.status);
        case "REC_LEADERBD":
            return state.setIn(["leaderboard", "data"], fromJS(action.data));
        case "REC_ASSETS_IN":
            return state.setIn(["assetsIn", action.address],  action.result);
        case "REC_WEFI_PRICE":
            if(state.hasIn(["accountSnapshot", WEFI_VAULT_ADDRESS])) {
                state = state.setIn(["accountSnapshot", WEFI_VAULT_ADDRESS, "oraclePrice"], decimal2Fixed(action.price, 8));
            }
            return state.set("wefiPrice", decimal2Fixed(action.price, 8));
        default:
            return state;
    }
}

function updateAccountData(state, data) {
    let keys =  Object.keys(data);
    for(let i=0; i < keys.length; i++) {
        state = state.set(keys[i], data[keys[i]]);
    }
    return state;
}


function  processAccountSnapshot(state, response) {
    const data = response.tokensData;
    const tokens = state.get("assets");
    let snapshot = fromJS({});
    let balances = fromJS({});
    const blocksPerDay = 37565;

    for ( let i=0; i< tokens.size; i++) {
        const tokenAddress = tokens.get(i).get("address");
        const row = data[i];
        snapshot = snapshot.setIn([tokenAddress, "lendTokens"], row.balanceOf);
        snapshot = snapshot.setIn([tokenAddress, "lendBalance"], row.balanceOfUnderlying);
        snapshot = snapshot.setIn([tokenAddress, "borrowBalance"], row.borrowBalanceStored);
        snapshot = snapshot.setIn([tokenAddress, "supplyRPBlock"], row.supplyRatePerBlock);
        snapshot = snapshot.setIn([tokenAddress, "borrowRPBlock"], row.borrowRatePerBlock);
        snapshot = snapshot.setIn([tokenAddress, "buyFactor"], fixed2Decimals(row.buyFactor, 18));
        snapshot = snapshot.setIn([tokenAddress, "totalCash"], row.totalCash);
        snapshot = snapshot.setIn([tokenAddress, "totalBorrowsCurrent"], row.totalBorrows);
        snapshot = snapshot.setIn([tokenAddress, "totalSupply"], row.totalSupply);
        snapshot = snapshot.setIn([tokenAddress, "exchangeRateStored"], row.exchangeRateStored);
        if (tokenAddress === WEFI_VAULT_ADDRESS) {
            snapshot = snapshot.setIn([tokenAddress, "oraclePrice"], state.get("wefiPrice"));
        } else {
            snapshot = snapshot.setIn([tokenAddress, "oraclePrice"], row.oraclePrice);
        }
        balances = balances.setIn([tokens.get(i).get("uAddress"), "balance"], row.underlyingBalance);
        var tmpLendAPY = (
            (Math.pow((Number(row.supplyRatePerBlock) / Number(decimal2Fixed(1, 18))) * blocksPerDay + 1,
            365
            ) -
            1)) *
            100;
        var tmpBorrowAPY = (Math.pow(
            (Number(row.borrowRatePerBlock) /  Number(decimal2Fixed(1, 18))) * blocksPerDay + 1,
            365
            ) -
            1) *
            100;
        snapshot = snapshot.setIn([tokenAddress, "lendAPY"], tmpLendAPY);
        snapshot = snapshot.setIn([tokenAddress, "borrowAPY"], tmpBorrowAPY);
        snapshot = snapshot.setIn([tokenAddress, "supplySpeed"], row.supplySpeed);
        // let supplyRewardAPR = 0;
        // let totalSupply = addFixed(row.totalBorrows, row.totalCash);
        // if(Number(row.supplySpeed) > 0 && Number(totalSupply > 0)) {
        //     let totalSupplyUSD = getUSDPrice(token.get("symbol").toLowerCase() === "wefi" ? decimal2Fixed(wefiPrice, 8) : row.oraclePrice, totalSupply, token.get("decimals"));
        //     supplyRewardAPR = getRewardAPR(row.supplySpeed, 18, wefiPrice, blocksPerDay, Number(totalSupplyUSD));
        // }
        // snapshot = snapshot.setIn([token.get("address"), "supplyRewardAPR"], supplyRewardAPR);
    }
    state = state.set("accountSnapshot", snapshot);
    state = state.setIn(["balanceAndAllowances", "uTokens"], balances);
    state = state.setIn(["portfolio", "accountLiquidity"], response.accountLiquidity)
    state = state.setIn(["portfolio", "accountLiquidityDecimals"], fixed2Decimals(response.accountLiquidity, 18, 2, 1))
    state = state.setIn(["portfolio", "compAccrued"], fixed2Decimals(response.compAccrued, 18, 4));
    return state;
}
