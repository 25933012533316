import { fromJS } from "immutable";
import { addFixed, divFixed, division, fixed2Decimals, getUSDPrice, minusFixed, mul, mulFixed } from "../../../utils";

const getDefaultSupply = () => {
    return fromJS({
        "balance": 0,
        "balanceTokens": 0,
        "redeemed": 0,
        "liquidatedTokens": 0
    })
}

const getDefaultBorrow = () => {
    return fromJS({
        "balance": 0,
        "borrow": 0,
        "repaid": 0,
        "borrowHistory": [],
        "repaidHistory": []
    });
}

const getDefaultBought  = (borrowAssets) => {
    let data =  fromJS({
        "balance": 0, //underlyning Balance
        "balanceTokens": 0, // token balance, liquidation only emits no of tokens, this will be used to display current postion balance and to decide if postion is closed.
        "borrow": {},
        "borrowHistory": [],
        "downpayment": {},
        "totalDownpayment": 0,
        "totalBorrow": 0,
        "repaid": {},
        "redeemed": 0,
        "liquidatedTokens": 0,
        "avgBuyingPrices": {}
    });

    borrowAssets.forEach((asset) => {
        const address = asset.get("address");
        const obj = fromJS({
            symbol: asset.get("symbol"),
            decimals: asset.get("decimals"),
            amount: 0
        });
        data = data.setIn(["borrow", address], obj);
        data = data.setIn(["downpayment", address], obj);
        data = data.setIn(["repaid", address], obj);
        data = data.setIn(["avgBuyingPrices", address], obj);
        // data = data.setIn(["borrowPer", address], 0);
    });
    return data;
}

export const generateTimeline = (allAssets, transactions, accountSnapshot) => {
    const assets = allAssets.map((asset) => {
        asset = asset.set("address", asset.get("address").toLowerCase());
        return asset;
    });
    const borrowAssets = assets.filter((item) => item.get("borrowEnabled"));
    let supplyData = fromJS({});
    let boughtData = fromJS({});
    let borrowData = fromJS({});

    assets.forEach((asset)=> {
        const assetAddress = asset.get("address");
        if (asset.get("borrowEnabled") === true || asset.get("lendEnabled") === true) {
            supplyData = supplyData.set(assetAddress, fromJS({
                "symbol": asset.get("symbol"),
                "decimals": asset.get("decimals"),
                "closed": []
            }));
            supplyData = supplyData.setIn([assetAddress, "current"], getDefaultSupply());
            borrowData = borrowData.set(assetAddress, fromJS({
                "symbol": asset.get("symbol"),
                "decimals": asset.get("decimals"),
                "current" : getDefaultBorrow(),
                "closed": [],
            }));
            borrowData = borrowData.setIn([assetAddress, "current"], getDefaultBorrow());
        } else {
            boughtData = boughtData.set(assetAddress, fromJS({
                "symbol": asset.get("symbol"),
                "decimals": asset.get("decimals"),
                "closed": [],
                "isShort": asset.get("isStable") === true
            }));
            boughtData = boughtData.setIn([assetAddress, "current"], getDefaultBought(borrowAssets));
        }
    });

    let oraclePrices = fromJS({}) 
    allAssets.forEach((item)=> {
        oraclePrices = oraclePrices.set(item.get("address").toLowerCase(), accountSnapshot.getIn([item.get("address"), "oraclePrice"]));
    })
    const txs = processTransactions(transactions);

    txs.forEach((event)=> {
        const type = event.get("eventName");
        let assetAddress = event.get("eventAddress");
        const eventData = event.get("eventData");
        const timestamp = event.get("timestamp");
        const asset = assets.find((item)=> item.get("address") === assetAddress);
        if (type === "Mint") {
            const isSupply = asset.get("lendEnabled") === true;
            if(isSupply) {
                let current = supplyData.getIn([assetAddress, "current"]);
                current =  current.set("balance", addFixed(
                    current.get("balance"),
                    eventData.get("mintAmount")
                ));
                current = current.set("balanceTokens", addFixed(
                    current.get("balanceTokens"), eventData.get("mintTokens")
                ));
                supplyData = supplyData.setIn([assetAddress, "current"], current);
            } else {
                let current = boughtData.getIn([assetAddress, "current"]);
                current = current.set("balance", addFixed(
                    current.get("balance"), 
                    eventData.get("mintAmount")
                ));
                current = current.set("balanceTokens", addFixed(
                    current.get("balanceTokens"),
                    eventData.get("mintTokens")
                ));

                boughtData = boughtData.setIn([assetAddress, "current"], current);
            }
        }
        if(type === "Borrow") {
            const borrowAssetAddress = assetAddress;
            assetAddress = eventData.get("boughtAsset");

            let currentBought = boughtData.getIn([assetAddress, "current"]);
            currentBought = currentBought.setIn(
                ["borrow", borrowAssetAddress, "amount"],
                addFixed(
                    currentBought.getIn(["borrow", borrowAssetAddress, "amount"]),
                    eventData.get("borrowAmount")
                )
            );
            currentBought = currentBought.setIn(
                ["downpayment", borrowAssetAddress, "amount"], 
                addFixed(
                    currentBought.getIn(["downpayment", borrowAssetAddress, "amount"]),
                    eventData.get("paidAmount")
                )
            );
            let borrowTimeline = currentBought.get("borrowHistory");
            borrowTimeline = borrowTimeline.push(fromJS({
                "borrowAsset": borrowAssetAddress,
                "boughtAsset": assetAddress,
                "borrowed": eventData.get("borrowAmount"),
                "downpayment": eventData.get("paidAmount"),
                "boughtAssetAmount": eventData.get("boughtAssetAmount"),
                "timestamp": event.get("timestamp")
            }));
            currentBought = currentBought.set("borrowHistory", borrowTimeline);
            boughtData = boughtData.setIn([assetAddress, "current"], currentBought);

            let currentBorrow = borrowData.getIn([borrowAssetAddress, "current"]);
            currentBorrow = currentBorrow.set("balance", addFixed(
                currentBorrow.get("balance"),
                eventData.get("borrowAmount")
            ));

            currentBorrow = currentBorrow.set("borrow", addFixed(
                currentBorrow.get("borrow"),
                eventData.get("borrowAmount")
            ));

            let borrowHistory = currentBorrow.get("borrowHistory");
            borrowHistory = borrowHistory.push(fromJS({
                "boughtAsset": assetAddress,
                "borrowAsset": borrowAssetAddress,
                "amount":  eventData.get("borrowAmount"),
                "downpayment": eventData.get("paidAmount"),
                "boughtAssetAmount": eventData.get("boughtAssetAmount"),
                "timestamp": timestamp
            }));
            currentBorrow = currentBorrow.set("borrowHistory", borrowHistory);
            borrowData = borrowData.setIn([borrowAssetAddress, "current"], currentBorrow);
        }

        // if (type === "Redeem" && data.has(assetAddress)) {
        if (type === "Redeem") {
            const amount = eventData.get("redeemAmount");
            const amountTokens = eventData.get("redeemTokens");

            let redeemInSupply= asset.get("lendEnabled") === true;
            if(redeemInSupply) {
                let currentSupply = supplyData.getIn([assetAddress, "current"]);
                let newBalance = minusFixed(currentSupply.get("balance"), amount);
                if(Number(newBalance) < 0) {
                    newBalance = "0";
                }
                let newBalanceTokens = minusFixed(currentSupply.get("balanceTokens"), amountTokens);
                currentSupply = currentSupply.set("balance", newBalance);
                currentSupply = currentSupply.set("balanceTokens", newBalanceTokens);
                currentSupply = currentSupply.set("redeemed", addFixed(
                    currentSupply.get("redeemed"),
                    amount
                ));
                supplyData = supplyData.setIn([assetAddress, "current"], currentSupply);
                if ( Number(newBalanceTokens) <= 0) {
                    //close position
                    let closed = supplyData.getIn([assetAddress, "closed"]);
                    closed = closed.push(supplyData.getIn([assetAddress, "current"]));
                    supplyData = supplyData.setIn([assetAddress, "closed"], closed);
                    supplyData = supplyData.setIn([assetAddress, "current"], getDefaultSupply());
                }
            } else {
                let currentBought = boughtData.getIn([assetAddress, "current"]);
                let newBalance = minusFixed(currentBought.get("balance"), amount);
                if(Number(newBalance) < 0) {
                    newBalance = "0";
                }
                let newBalanceTokens = minusFixed(currentBought.get("balanceTokens"), amountTokens);
                currentBought = currentBought.set("balance", newBalance);
                currentBought = currentBought.set("balanceTokens", newBalanceTokens);
                currentBought = currentBought.set("redeemed", addFixed(
                    currentBought.get("redeemed"),
                    amount
                ));
                boughtData = boughtData.setIn([assetAddress, "current"], currentBought);
                if ( Number(newBalanceTokens) <= 0) {
                    //close position
                    let closed = boughtData.getIn([assetAddress, "closed"]);
                    closed = closed.push(boughtData.getIn([assetAddress, "current"]));
                    boughtData = boughtData.setIn([assetAddress, "closed"], closed);
                    boughtData = boughtData.setIn([assetAddress, "current"], getDefaultBought(borrowAssets));
                }
            } 
        }

        if (type === "SwapAndSettle") {
            let currentBought = boughtData.getIn([assetAddress, "current"]);
            const amount = eventData.get("numTokensAmount");
            const amountTokens = eventData.get("numTokens");
            const newBalance = minusFixed(currentBought.get("balance"), amount);
            const newBalanceTokens = minusFixed(currentBought.get("balanceTokens"), amountTokens);

            currentBought = currentBought.set("balance", newBalance);
            currentBought = currentBought.set("balanceTokens", newBalanceTokens);
            currentBought = currentBought.set("redeemed", addFixed(
                currentBought.get("redeemed"),
                amount
            ));
           
            boughtData = boughtData.setIn([assetAddress, "current"], currentBought);
            if (newBalanceTokens <= 0) {
                //close position
                let closed = boughtData.getIn([assetAddress, "closed"]);
                closed = closed.push(boughtData.getIn([assetAddress, "current"]));
                boughtData = boughtData.setIn([assetAddress, "closed"], closed);
                boughtData = boughtData.setIn([assetAddress, "current"], getDefaultBought(borrowAssets));
            }
        }

        if( type === "LiquidateBorrow") {
            // to-do : calculate percentage of tokens liquidated to display
            assetAddress = eventData.get("cTokenCollateral");
            let currentObj = boughtData.has(assetAddress) ? boughtData.getIn([assetAddress, "current"]) : supplyData.getIn([assetAddress, "current"]);
            const amountTokens = eventData.get("seizeTokens");
            const newBalanceTokens = minusFixed(
                currentObj.get("balanceTokens"),
                amountTokens
            );
            currentObj = currentObj.set("balanceTokens", newBalanceTokens);
            currentObj = currentObj.set("liquidatedTokens", addFixed(
                currentObj.get("liquidatedTokens"),
                amountTokens
            ));
            if (boughtData.has(assetAddress)) {
                boughtData = boughtData.setIn([assetAddress, "current"], currentObj);
                if (Number(newBalanceTokens) <= 0) {
                    //close position
                    let closed = boughtData.getIn([assetAddress, "closed"]);
                    closed = closed.push(boughtData.getIn([assetAddress, "current"]));
                    boughtData = boughtData.setIn([assetAddress, "closed"], closed);
                    boughtData = boughtData.setIn([assetAddress, "current"], getDefaultBought(borrowAssets));
                }
            } else {
                supplyData = supplyData.setIn([assetAddress, "current"], currentObj);
                if (Number(newBalanceTokens) <= 0) {
                    //close position
                    let closed = supplyData.getIn([assetAddress, "closed"]);
                    closed = closed.push(supplyData.getIn([assetAddress, "current"]));
                    supplyData = supplyData.setIn([assetAddress, "closed"], closed);
                    supplyData = supplyData.setIn([assetAddress, "current"], getDefaultSupply(borrowAssets));
                }
            }
        }

        if(type === "RepayBorrow") {
            //In assets's current postion, based on borrow amounts, distribute repay amount.
            let repayAmount = eventData.get("repayAmount");
            let currentBorrow = borrowData.getIn([assetAddress, "current"]);
            let newBalance = minusFixed(currentBorrow.get("balance"), repayAmount);
            if (Number(newBalance) < 0) {
                newBalance = 0;
            }
            currentBorrow = currentBorrow.set("balance", newBalance);
            currentBorrow = currentBorrow.set("repaid", addFixed(
                currentBorrow.get("repaid"),
                repayAmount
            ));
            let repaidHistory = currentBorrow.get("repaidHistory");
            repaidHistory = repaidHistory.push(fromJS({
                "amount": repayAmount,
                "timestamp": timestamp
            }));
            currentBorrow = currentBorrow.set("repaidHistory", repaidHistory);
            borrowData = borrowData.setIn([assetAddress, "current"], currentBorrow);
            if(Number(newBalance) <= 0) {
                let closed  = borrowData.getIn([assetAddress, "closed"]);
                closed  = closed.push(borrowData.getIn([assetAddress, "current"]));
                borrowData = borrowData.setIn([assetAddress, "closed"], closed);
                borrowData = borrowData.setIn([assetAddress, "current"], getDefaultBorrow());
            }
        }
    });

    // overall current stats
    let overall = fromJS({
        totalBorrow: 0,
        totalDownpayment: 0,
        totalRepaid: 0,
        borrowPer: {}
    });
    assets.filter((item)=> item.get("buyEnabled") === true).forEach((asset)=> {
        const assetAddress = asset.get("address");
        borrowAssets.forEach((borrowAsset) => {
            const borrowAssetAddress = borrowAsset.get("address").toLowerCase();
            // calc avg buying price
            let avgBuyingPrice = 0;
            let totalBought = 0;
            const borrowItems = boughtData.getIn([assetAddress, "current", "borrowHistory"]).filter((item)=> item.get("borrowAsset") === borrowAssetAddress);
            borrowItems.forEach((item)=> {
                totalBought = totalBought + Number( fixed2Decimals(item.get("boughtAssetAmount"), asset.get("decimals")));
                avgBuyingPrice = avgBuyingPrice + Number(item.get("borrowed")) + Number(item.get("downpayment"));
            });
            avgBuyingPrice = totalBought > 0 ? divFixed(avgBuyingPrice, totalBought) : 0;
           
            boughtData = boughtData.setIn([ assetAddress, "current", "avgBuyingPrices", borrowAssetAddress, "amount"], avgBuyingPrice);
            
            if(borrowAsset.get("isStable")=== true) {
                // handle long
                boughtData = boughtData.setIn(
                    [assetAddress, "current", "totalBorrow"],
                    boughtData.getIn([assetAddress, "current", "totalBorrow"]) + Number(getUSDPrice(
                        oraclePrices.get(borrowAssetAddress),
                        boughtData.getIn([assetAddress, "current", "borrow", borrowAssetAddress, "amount"]),
                        borrowAsset.get("decimals")
                    ))
                )
    
                boughtData = boughtData.setIn(
                    [assetAddress, "current", "totalDownpayment"],
                    boughtData.getIn([assetAddress, "current", "totalDownpayment"]) + Number(getUSDPrice(
                        oraclePrices.get(borrowAssetAddress),
                        boughtData.getIn([assetAddress, "current", "downpayment", borrowAssetAddress, "amount"]),
                        borrowAsset.get("decimals")
                    ))
                )
                overall = overall.set(
                    "totalDownpayment", 
                    overall.get("totalDownpayment") + Number(getUSDPrice(
                        oraclePrices.get(borrowAssetAddress),
                        boughtData.getIn([assetAddress, "current", "downpayment", borrowAssetAddress, "amount"]),
                        borrowAsset.get("decimals")
                    ))
                );
            } else {
                //handle short
                borrowItems.forEach((item)=> {
                    // console.log(borrowAsset.get("symbol"), ">>>>>>>>>>>>>>>>>");
                    const stableAmount = fixed2Decimals(item.get("boughtAssetAmount"), asset.get("decimals"));
                    const borrowAmount = fixed2Decimals(item.get("borrowed"), borrowAsset.get("decimals"));
                    const downpaymentAmount = fixed2Decimals(item.get("downpayment"), borrowAsset.get("decimals"));
                    // console.log(stableAmount, borrowAmount, downpaymentAmount);

                    const totalInAmount = Number(borrowAmount) +  Number(downpaymentAmount);
                    const unitPrice = Number(stableAmount)/ Number(totalInAmount);
                    // console.log(totalInAmount, unitPrice);
                    const borrowPrice = Number(borrowAmount) * unitPrice;
                    const downpaymentPrice = Number(downpaymentAmount) *  unitPrice;
                    // console.log(overall.get("totalBorrow"), borrowPrice, downpaymentPrice);
                    
                    boughtData = boughtData.setIn(
                        [assetAddress, "current", "totalBorrow"],
                        boughtData.getIn([assetAddress, "current", "totalBorrow"]) + Number(borrowPrice)
                    )
        
                    boughtData = boughtData.setIn(
                        [assetAddress, "current", "totalDownpayment"],
                        boughtData.getIn([assetAddress, "current", "totalDownpayment"]) + Number(downpaymentPrice)
                    )
                    overall = overall.set(
                        "totalDownpayment", 
                        overall.get("totalDownpayment") + Number(downpaymentPrice)
                    );

                });

            }

           
        });
    })

    borrowAssets.forEach((borrowAsset)=> {
        const borrowAssetAddress = borrowAsset.get("address");
        if (borrowAsset.get("isStable") === true) {
            overall = overall.set(
                "totalBorrow", 
                overall.get("totalBorrow") + Number(getUSDPrice(
                    oraclePrices.get(borrowAssetAddress),
                    borrowData.getIn([borrowAssetAddress, "current", "borrow"]),
                    borrowAsset.get("decimals")
                ))
            );
        } else {
            //handle short
            const borrowItems = borrowData.getIn([borrowAssetAddress, "current", "borrowHistory"]);
            borrowItems.forEach((item)=> {
                const asset = assets.find((el)=> el.get("address") === item.get("boughtAsset"));
                // console.log(borrowAsset.get("symbol"), ">>>>>>>>>>>>>>>>>");
                const stableAmount = fixed2Decimals(item.get("boughtAssetAmount"), asset.get("decimals"));
                const borrowAmount = fixed2Decimals(item.get("amount"), borrowAsset.get("decimals"));
                const downpaymentAmount = fixed2Decimals(item.get("downpayment"), borrowAsset.get("decimals"));
                // console.log(stableAmount, borrowAmount, downpaymentAmount);

                const totalInAmount = Number(borrowAmount) +  Number(downpaymentAmount);
                const unitPrice = Number(stableAmount)/ Number(totalInAmount);
                // console.log(totalInAmount, unitPrice);
                const borrowPrice = Number(borrowAmount) * unitPrice;
                const downpaymentPrice = Number(downpaymentAmount) *  unitPrice;
                // console.log(overall.get("totalBorrow"), borrowPrice, downpaymentPrice);
                
                overall = overall.set(
                    "totalBorrow",
                    overall.get("totalBorrow") + Number(borrowPrice)
                )
            })
            // overall = overall.set(
            //     "totalBorrow", 
            //     overall.get("totalBorrow") + Number(getUSDPrice(
            //         oraclePrices.get(borrowAssetAddress),
            //         borrowData.getIn([borrowAssetAddress, "current", "borrow"]),
            //         borrowAsset.get("decimals")
            //     ))
            // );
        }
       

        overall = overall.set(
            "totalRepaid", 
            overall.get("totalRepaid") + Number(getUSDPrice(
                oraclePrices.get(borrowAssetAddress),
                borrowData.getIn([borrowAssetAddress, "current", "repaid"]),
                borrowAsset.get("decimals")
            ))
        );
    })

    assets.filter((item)=> item.get("buyEnabled")).forEach((asset)=> {
        let current = boughtData.getIn([asset.get("address"), "current"]);
        // console.log("Borrow Per", asset.get("symbol"), overall.get("totalBorrow"), current.get("totalBorrow"));
        current = current.set("borrowPer", overall.get("totalBorrow") > 0  ? current.get("totalBorrow")/overall.get("totalBorrow"): 0);
        // current = current.set("borrowPer", overall.get("totalBorrow") > 0  ? current.get("borrow")/overall.get("totalBorrow"): 0);
        boughtData = boughtData.setIn([asset.get("address"), "current"], current);
    });

    // calculate borrow percentage for assets
  
    // console.log(data.toJS(), overall.toJS());
    // console.log(data.toJS());
    return {boughtData, boughtDataOverall: overall, supplyData, borrowData};
}

const processTransactions = transactions => {
    let data = transactions.map((tx)=> {
        if (tx.has("data")) {
            tx = tx.set("eventData", fromJS(JSON.parse(tx.get("data"))));
        }
        return tx;
    }).sort((a, b)=>Number(a.get("timestamp")) - Number(b.get("timestamp")));
    // rearrange if repay is emitted after swapNSettle
    data.forEach((tx, i) => {
        if (i> 0 && tx.get("eventName") === "RepayBorrow" && data.get(i-1).get("transactionHash") === tx.get("transactionHash")) {
            const settleTx = data.get(i-1);
            data = data.set(i-1, tx);
            data= data.set(i, settleTx);
        }
    }) 
    return data;
}