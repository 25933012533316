import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import StarGateLogoLight from '../../images/stargate-light.svg';
import StarGateLogoDark from '../../images/stargate-dark.svg';

function Bridge(props) { 
  
    return (
        <Container className="mt-4">
            {/* <WalletBalances /> */}
            <Row>
                <Col md={6} sm={12}>
                    <div className="d-flex flex-column align-items-center justify-content-center" style={{height: "70%"}}>
                        <div className="text-light-1 fs-4">
                            Transfer assets cross-chain.
                        </div>
                        <div className="text-light-2 fw-normal" mt={5}>
                            {[
                                "Seamless Single Transaction Process",
                                "Instant Guaranteed Finality",
                                "Native Assets",
                                "Unified Liquidity"
                            ].map((text, i)=> {
                                return <div className="mt-2">
                                <i className="fas fa-check-circle me-2"/>
                                {text}
                            </div>
                            })}
                            {/* <div className="text-light-1 text-center mt-5">
                                <span className="me-4">powered by</span>
                                <img height="40px" src={props.theme==="dark"? StarGateLogoDark : StarGateLogoLight} alt="stargate"/>
                            </div> */}
                        </div>
                    </div>
                </Col>
                <Col md={4} sm={12}>
                    <stargate-widget  
                        theme={props.theme}
                        partnerId="0x0024"
                        feeCollector="0xd08b37B15134c158DABcD4d09ea0207465Ea5e4D"
                        tenthBps="100"
                    />
                </Col>
                
            </Row>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        connected: state.get("connected"),
        theme: state.get("themeMode")
    }
}

export default connect(mapStateToProps, {})(Bridge);