import { OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import { connect } from "react-redux"
import { getTokenIcon } from "../../../utils";

function TokenRewards(props) {
    const getPopover = () => {
        return (
            <Popover id="reward-tokens">
                {/* {title && <Popover.Header as="h3">{title}</Popover.Header>} */}
                <Popover.Body>
                    Follow the community to know about claiming the rewards.
                    <hr/>
                    <div className="text-center">
                        <a className="me-3" target="_blank" rel="noreferrer" href="https://t.me/+9EeOlB7AKl5hMDZl"><i className="text-body fas fa-2x fa-brands fa-telegram" /></a>
                        <a className="me-3" target="_blank" rel="noreferrer" href="https://discord.com/invite/B6U6kectbV"><i className="text-body fas fa-2x fa-brands fa-discord" /></a>
                        <a className="" target="_blank" rel="noreferrer" href="https://twitter.com/wefi_xyz"><i className="text-body fas fa-2x fa-brands fa-twitter" /></a>
                    </div>
                </Popover.Body>
            </Popover>
        )
    }
    return <div>
            <div className="">
                <i className="fas fa-star-half-stroke me-2 text-secondary"/>
                <span className="text-light-1 me-2">Rewards Collected:</span>
                <img src={getTokenIcon("WEFI")} height="20" alt="wefi" className="me-1"/>
                {props.isLoading ? <Spinner size="sm" vairant="secondary" animation="grow"/> : `${Number(props.compAccrued) > 0 ? props.compAccrued: "0.00"}`}
                <OverlayTrigger trigger="click" placement="bottom" overlay={getPopover()}>
                    <i className="fas fa-info-circle ms-2 cursor-pointer"/>
                </OverlayTrigger>
            </div>
    </div>
}

const mapStateToProps = state => {
    return {
        web3: state.get("web3"),
        compAddress: state.getIn(["contracts", "comptroller"]),
        themeMode: state.get("themeMode"),
        accAddress: state.get("accAddress"),
        compAccrued: state.getIn(["portfolio", "compAccrued"]),
        isLoading: state.getIn(["balanceAndAllowances", "isLoading"])
    }
}


export default connect(mapStateToProps, null)(TokenRewards)