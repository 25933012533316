import {useState} from 'react';
import {connect} from 'react-redux';
import {Row, Col, Button, Alert} from 'react-bootstrap';
import { decimal2Fixed, fixed2Decimals, getContract, getTokenIcon, getUSDPrice } from '../../../../utils';
import { supplyTokens } from '../../../../redux/actions';
import { pTokenABI } from '../../../../utils/abi';
import ApprovalModal from '../ApprovalModal';

function Lend(props) {
    const [showApprove, setShowApprove] = useState(false);
    const [sliderBalance, setSliderBalance] = useState(0);
    const token = props.token;
    let walletBalance = 0;
    if ( props.uTokenMeta.hasIn([token.get("uAddress"), "balance"]) ) {
        let balance = Number(props.uTokenMeta.getIn([token.get("uAddress"), "balance"]));
        walletBalance = fixed2Decimals(balance, token.get("decimals"));
    }

    const getAmount = () => {
        let amount = Number(sliderBalance);
        if(token.get("isStable") !== true) {
            amount = Number(
                getUSDPrice(props.snapshot.getIn([token.get("address"), "oraclePrice"]), sliderBalance, token.get("decimals"), false)
            );
        }
        return amount;
    }

    const supply = () => {
        const supplyAmount =  decimal2Fixed(sliderBalance, token.get("decimals"));
        const contract = getContract(props.web3, pTokenABI, token.get("address"));
        const data  =  {
            txDesc: `Supply ${token.get("symbol")} ${sliderBalance}`
        }
        props.supplyTokens(props.web3, contract, props.accAddress, supplyAmount, data, props.gasType);
        setShowApprove(false);
        props.onHide();
    }

    const amountValue = getAmount();
    
    return(
        <div>
            <small className='text-light-1'>Supply Rates</small>
            <Row className='mt-1'>
                <Col>
                    <img height="20" className='me-2' src={getTokenIcon(token.get("symbol"))} alt={token.get("symbol")}/>
                    <span className='text-light-2 fw-bold'>Supply APY</span>
                </Col>
                <Col className="text-end text-dark fw-bold">
                    {props.lendAPY}
                </Col>
            </Row>
            <Row className="mt-4 mb-2 align-items-center">
                <Col xs={7} className="border-end border-2">
                    <div className=""><small className='text-light-1'>Supply {token.get("symbol")}</small></div>
                    <div className="input-group mt-1 ">
                        <input
                            value={sliderBalance}
                            className="form-control"
                            onChange={(e)=> setSliderBalance(e.target.value)}
                            type="number"
                        />
                        <button onClick={()=> setSliderBalance(walletBalance)}className="btn btn-primary">Max</button>
                    </div>
                </Col>
                <Col>
                    <div><small className='text-light-1'> <i className="fa fa-wallet me-2" />{token.get("symbol")} Balance</small></div>
                    <div className="fw-bold mt-1">
                        <img height="20" className='me-2' src={getTokenIcon(token.get("symbol"))} alt={token.get("symbol")}/>
                        {walletBalance} 
                    </div>   
                </Col>
            </Row>

            {Number(sliderBalance) > walletBalance && <Alert variant="secondary" className="mt-4 small fw-bold">
                <small>
                    Insufficient wallet balance
                    <span className="ms-3 me-3 text-muted">|</span>
                    <i className="fa fa-wallet"/> {walletBalance} {token.get("symbol")}
                </small>
            </Alert>}

            {/*Number(sliderBalance) > 0 && amountValue < 50 &&  <Alert variant="secondary" className="mt-4 small fw-bold">
                <small>
                    Minimum {token.get("isStable") ? <img height="12" src={getTokenIcon(token.get("symbol"))} alt="token"/> : "$"} 50 supply amount is required.
                </small>
                {token.get("isStable") !== true && <div>
                    <small>
                        {sliderBalance} {token.get("symbol")} = ${amountValue}
                    </small>
                </div>}
            </Alert>*/}

            {!showApprove && <div className='d-grid mt-5'>
                {props.accessStatus !==1 && <div className='mb-2 text-light-1'>
                    <small><i className="fa fa-info-circle me-2"/>You are not whitelisted to use the protocol.</small><br/>
                </div>}
                <Button
                    disabled={
                        props.accessStatus!==1 || Number(sliderBalance) <=0 || Number(sliderBalance) > walletBalance} size="lg"
                    onClick={()=> {
                        if(props.accessStatus ===1) {
                            setShowApprove(true);
                        }
                    }}
                >LEND</Button>
            </div>}
            {showApprove && <ApprovalModal
                desc={`Supply ${sliderBalance} ${token.get("symbol")}`}
                show={showApprove}
                amount = { decimal2Fixed(sliderBalance, token.get("decimals")) }
                token = {props.token}
                onSuccess= {()=> supply()}
                onHide= {()=> setShowApprove(false)}
            />}
        </div>
    ) 
}

const mapStateToProps = (state, props) => {
    return {
        uTokenMeta: state.getIn(["balanceAndAllowances", "uTokens"]),
        snapshot: state.get("accountSnapshot"),
        web3: state.get("web3"),
        accAddress: state.get("accAddress"),
        accessStatus: state.getIn(["accessStatus", "status"]),
        gasType: state.getIn(["gasPrices", "active"])
    }
}

const mapDispatchToProps = dispatch => {
    return {
        supplyTokens: (web3, contract, accAddress, amount, data, gasType)=> dispatch(supplyTokens(web3, contract, accAddress, amount, data, gasType))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Lend);