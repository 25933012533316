import {useState} from 'react';
import {Modal, Row, Col} from 'react-bootstrap';
import { connect } from 'react-redux';
import { getTokenIcon } from '../../../utils';
import Settings from '../../../utils/settings/Container';
import Borrow from './modal/BorrowV2';
import Repay from './modal/RepayV2';
import ConnectWallet from '../../../components/ConnectWallet';

function BorrowModal(props) {
    const [activeTab, setActiveTab] = useState(props.activeTab ? props.activeTab : "Borrow & Buy");
    const [activeBorrowToken, setActiveBorrowToken] = useState(props.tokens.get("borrowAssets").get(0));

    let tabs = ["Borrow & Buy", "Repay"];
    if(props.repayOnly) {
        tabs = ["Repay"]
    }

    const targetToken = props.tokens.get("targetToken");

    const getTabContent = () => {
        
        if(activeTab === "Borrow & Buy") {
            return <Borrow
                targetToken={targetToken}
                borrowToken={activeBorrowToken}
                borrowAssets={props.tokens.get("borrowAssets")}
                borrowAPY ={props.getAPY(activeBorrowToken.get("address"))}
                onBorrowTokenChange = {(token) => setActiveBorrowToken(token)}
                onHide={()=> props.onHide()}
                onConnect={()=> props.onConnect()}
            />
        } else {
            if(props.connected) {
                return <Repay 
                    token={activeBorrowToken}
                    borrowAssets={props.tokens.get("borrowAssets")}
                    onBorrowTokenChange = {(token) => setActiveBorrowToken(token)}
                    onHide={()=> props.onHide()}
                />
            } else {
                return <ConnectWallet 
                    onConnect={()=> props.onConnect()}
                    themeMode={props.themeMode}
                />
            }
            
        }
    }

   
    return(
        <Modal
            show={props.show}
            onHide={()=> props.onHide()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            contentClassName={props.themeMode === "dark" ? "bg-black-modal": ""}
        >
            <Modal.Header closeButton className="position-relative text-center border-0">
                {props.repayOnly !== true && <Modal.Title id="contained-modal-title-vcenter">
                    <div className='modal-header-token'>
                        <div className="icon">
                            <img src={getTokenIcon(targetToken.get("symbol"))} alt={targetToken.get("symbol")}/>
                        </div>
                        <div className='text-light-2 mt-2 fs-5'>{targetToken.get("symbol")}</div>
                    </div>
                </Modal.Title>}
                <Settings/>
            </Modal.Header>
            <Modal.Body>
                <Row className='modal-tab g-0'>
                    {tabs.map((tab, i)=> {
                        return (
                            <Col
                                onClick={()=> setActiveTab(tab)}
                                className={`modal-tab-item ${tab === activeTab ? "active" : ""}`}
                                key={i}
                            >
                                {tab} 
                            </Col>
                        ) 
                    })}
                </Row>
                <div className='p-4'>
                    {getTabContent()}
                </div>
            </Modal.Body>
        </Modal>
    ) 
}

const mapStateToProps = state => {
    return {
        connected: state.get("connected"),
        themeMode: state.get("themeMode")
    }
 }   
 
 export default connect(mapStateToProps, {})(BorrowModal);