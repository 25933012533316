import {Modal, Row, Col, Button, Badge} from 'react-bootstrap';
import {connect} from 'react-redux';
import {fixed2Decimals} from '../../utils';
import PaxoIcon from '../../../images/icon.png';

function Detail(props) {
    const tokenAmountPerClaim = fixed2Decimals(props.nft.getIn(["tier", "tokenAmountPerClaim"]), "18")
    const maxTokenClaim = fixed2Decimals(props.nft.getIn(["tier", "maxTokenClaim"]), "18");
    return(
        <Modal
            show={props.show}
            onHide={()=> props.onHide()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            contentClassName={props.themeMode === "dark" ? "bg-black-modal": ""}
        >
            <Modal.Header closeButton className="text-light-1">
                {props.nft.getIn(["tier", "name"]).toUpperCase()}
            </Modal.Header>
            <Modal.Body>
                <div className={`py-2 px-2 ${props.themeMode === "dark"? "bg-dark" : "bg-beige border shadow-lg"} rounded-3`}>
                    <Row className='align-items-center'>
                        <Col md={9}>
                            {props.nft.hasIn(["campaign", "name"]) && props.nft.getIn(["campaign", "name"]) !== "" &&  <div>
                                <div className='text-light-1'>{props.nft.getIn(["campaign", "name"])}</div>
                                <small className='text-light-2'>{props.nft.getIn(["campaign", "details"])}</small>
                                <hr/>
                            </div>}
                            <div className='text-light-1'>{props.nft.getIn(["tier", "name"])}</div>
                            <div><small className='text-light-2'>{props.nft.getIn(["tier", "details"])}</small></div>
                            {props.nft.hasIn(["tier", "tags"]) && props.nft.getIn(["tier", "tags"]).map((tag, i)=> <Badge className="me-2">{tag}</Badge>)}
                            {props.nft.hasIn(["tier", "xp"]) && Number(props.nft.getIn(["tier", "xp"])) > 0 && 
                               <Badge>XP {props.nft.getIn(["tier", "xp"])}</Badge>}
                        </Col>
                        <Col className='text-end'>
                            <img width="100%" className='rounded-3' src={props.nft.getIn(["nft", "url"])} alt="nft"/>
                        </Col>
                    </Row>
                </div>
                {Number(maxTokenClaim) > 0 && <div>
                    <Row className='mt-4'>
                        <Col className=''>
                            <div className="title text-light-2">Token Amount</div>
                            <div className="value"><img src={PaxoIcon} height="16" alt="paxo"/> {maxTokenClaim}</div>
                        </Col>
                        <Col  className=''>
                            <div className="title text-light-2">Max Tokens Per Claim</div>
                            <div className="value"><img src={PaxoIcon} height="16" alt="paxo"/> {tokenAmountPerClaim}</div>
                        </Col>
                    </Row>

                    <Row className='mt-2'>
                        <Col className=''>
                            <div className="title text-light-2">Every Unlock in</div>
                            <div className="value">{props.nft.getIn(["tier", "claimThresholdDays"])} Days</div>
                        </Col>
                        <Col  className=''>
                            <div className="title text-light-2">Cliff Period</div>
                            <div className="value">{props.nft.getIn(["tier", "cliffPeriod"])} Days</div>
                        </Col>
                    </Row>
                </div>}
                {props.nft.hasIn(["tier", "disclaimer"]) && props.nft.getIn(['tier', "disclaimer"]) !== "" &&  <div className={`py-2 px-2 mt-4 ${props.themeMode === "dark"? "bg-dark" : "bg-beige border shadow-lg"} rounded-3`}>
                    <Row className='align-items-center'>
                        <Col md={1}>
                            <i className='fas fa-info-circle'></i>
                        </Col>
                        <Col className='small text-light-1' dangerouslySetInnerHTML={{__html: props.nft.getIn(["tier", "disclaimer"])}}>
                        </Col>
                    </Row>
                </div>}

                {props.nft.hasIn(["tier", "terms"]) && props.nft.getIn(["tier", "terms"]) !== "" &&  <div>
                <div className='text-light-1 mt-4'>Terms and Conditions </div>
                <div className='text-light-2 small' dangerouslySetInnerHTML={{__html: props.nft.getIn(["tier", "terms"])}}></div>
                </div>}
               
            </Modal.Body>
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        themeMode: state.get("themeMode")
    }
 }   
 
 export default connect(mapStateToProps, {})(Detail);