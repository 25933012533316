import {useState} from 'react';
import {connect} from 'react-redux';
import {Card, Row, Col, Button} from 'react-bootstrap';
import {fixed2Decimals, getTokenIcon, getUSDPrice, numFormatter, toPrecisin} from '../../../utils';
import {fromJS} from 'immutable';
import BorrowModal from './BorrowModalV2';
import BorrowingAssetsV2, { Item } from '../../portfolio/components/BorrowingAssetsV2';
import { generateTimeline } from '../../portfolio/components/calc';

function BorrowAssets(props) {
    const [activeToken, setActiveToken] = useState(null);

    const getAPY = (address) => {
        let apy = props.accountSnapshot.getIn([address, "borrowAPY"]);
        return apy ? `${toPrecisin(apy, 4)}%` : "---" ;
    }

    // const getBuyFactor = (address) => {
    //     let b = props.accountSnapshot.getIn([address, "buyFactor"]);
    //     return b ? `${b}x` : "---" ;
    // }
    const {boughtData, boughtDataOverall, borrowData} = generateTimeline(props.assets, props.transactions, props.accountSnapshot);
    
    return (
        <div>
            {props.connected && <BorrowingAssetsV2 
                data = {boughtData}
                overallCurrent={boughtDataOverall}
                borrowData={borrowData}
            />}
            <Card body className="mt-5">
                <Row>
                    <Col className="g-0">
                        <div className="fs-6">Available Investment Vaults</div>
                        <div className="small text-muted">Tokens you can buy by borrowing other tokens</div>
                    </Col>
                </Row>
                <div className="token-list">
                    {props.assets.filter((item)=> item.get("buyEnabled")).map((token, j)=> <BorrowItem 
                        key={j}
                        token={token}
                        accountSnapshot={props.accountSnapshot}
                        // borrowAssets={props.assets.filter((item)=> item.get("borrowEnabled") && item.get("address") !== token.get("address"))}
                        borrowAssets={props.assets.filter((item)=> token.get("borrowAssets").includes(item.get("address")))}
                        onBuy={(borrowAssets)=> setActiveToken(fromJS({targetToken: token.toJS(), borrowAssets: borrowAssets.toJS()}))}
                        oraclePrice = {props.accountSnapshot.getIn([token.get("address"), "oraclePrice"])}
                        themeMode={props.themeMode}
                    />)}
                </div>
                {activeToken !==null && <BorrowModal
                    show={activeToken !== null}
                    tokens={activeToken}
                    getAPY={(address) => getAPY(address)}
                    onHide={()=> setActiveToken(null)}
                    onConnect={()=> props.onConnect()}
                />}
            </Card>
        </div>
    )
}


function BorrowItem(props) {
    // const [activeRoi, setActiveRoi] = useState(0);
    const token = props.token;
    let stats = fromJS({
        "interests": [],
        "interests_range": "---",
        "cash": 0 ,
        "cashItems": {}
    });
    props.borrowAssets.forEach((asset)=> {
        const cashAmount = props.accountSnapshot.getIn([asset.get("address"), "totalCash"]);
        const cashUSD = Number(getUSDPrice(
            props.accountSnapshot.getIn([asset.get("address"), "oraclePrice"]),
            cashAmount,
            asset.get("decimals")
        ))
        stats = stats.setIn(["cashItems", asset.get("address")], fromJS({
            "amount": cashAmount, "symbol": asset.get("symbol"), "decimals": asset.get("decimals")
        }));
        stats = stats.set("cash", stats.get("cash") + cashUSD);

        let apy = props.accountSnapshot.getIn([asset.get("address"), "borrowAPY"]);
        if (apy > 0) {
            let interests = stats.get("interests");
            interests = interests.push(apy);
            stats = stats.set("interests", interests);
        }
    })
    if (stats.get("interests").size > 0) {
        let range = stats.get("interests").sort((a, b)=> a-b);
        stats = stats.set("interests_range", `${range.get(0).toFixed(2)}% ${range.size > 1  ? ` - ${range.get(range.size-1).toFixed(2)}%` : ""}`);
    }

    return (
        <Row  className="token-list-item align-items-center">
            <Col xs={4} sm={6} md={2}>
                <div className="title">Vault</div>
                <div className="value"><img src={getTokenIcon(token.get("symbol"))} alt="token"/>{token.get("symbol")}</div>
            </Col>
            {/* <Col>
                <div className="title">
                    Historical ROI
                </div>
                <div className="value">
                    <div>1.4%</div>
                    <div className="small">{["30D", "6M", "1Y"].map((item, i)=> <span 
                        onClick={()=> setActiveRoi(i)}
                        className={`px-2 me-2 text-light-1 rounded-2  small bg-${i=== activeRoi?  props.themeMode === "dark"? "black border": "dark text-white" : props.themeMode === "dark"? "dark": "white border  border-1"}`} style={{
                        cursor: "pointer"
                    }}
                    key={i}
                    >{item}</span>)}
                    </div>
                </div>
            </Col> */}
            <Col xs={4} sm={6} md={2}>
                <div className="title">Spot Price</div>
                <div className="value">${fixed2Decimals(props.oraclePrice, 8, 2)}</div>
            </Col>
            <Col xs={4} sm={6} md={1}>
                <div className="title">Leverage</div>
                <div className="value">Upto 5x</div>
            </Col>
            <Col xs={6} sm={6} md={2}>
                <div className="title">Borrow</div>
                {props.borrowAssets.map((item, i)=> <img key={i} height="20" className='me-2' src={getTokenIcon(item.get("symbol"))} alt="token"/>)}
            </Col>
            <Col xs={6} sm={6} md={2}>
                <div className="title">Borrow Interest</div>
                <div className="value">{stats.get("interests_range")}</div>
            </Col>
            <Col xs={6} sm={6} md={2}>
                <div className="title">Available to Borrow</div>
                <div className="value">
                    <Item value={numFormatter(stats.get("cash").toFixed(2))} isPrice items={stats.get("cashItems")} />
                </div>
            </Col>
            <Col xs={6} sm={6} md={1} className='d-grid justify-content-end'>
                <Button onClick={()=> props.onBuy(props.borrowAssets)} variant="primary" className="action">Invest</Button>
            </Col>
        </Row>

    )
}

const mapStateToProps = state => {
    return {
        connected: state.get("connected"),
        assets: state.get("assets"),
        accountSnapshot: state.get("accountSnapshot"),
        themeMode: state.get("themeMode"),
        transactions: state.getIn(["transactions", "data"])
    }
}

export default connect(mapStateToProps, {})(BorrowAssets);