import {Container, Button, Row, Col, Spinner, Badge} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { getContract } from '../../utils';
import { nftABI } from '../../utils/abi';
import { mintNft, fetchNftData } from '../../redux/actions';
import Detail from './DetailsModal';
import ConnectWallet from '../../components/ConnectWallet';


function Rewards (props) {
    const [showDetail, setShowDetail] = useState(false);
    const [activeNFT, setActiveNFT] = useState();

    useEffect (()=> {
        if(props.connected) {
            props.web3.eth.chainId().then((chainId)=> {
                props.fetchNftData(contract, props.accAddress, chainId);
            });
        }
    }, [props.accAddress]);

    if(!props.connected) {
        return   <ConnectWallet
            onConnect = {() => props.onConnect()}
            themeMode={props.themeMode}
        />
    }

    const contract = getContract(props.web3, nftABI, props.paxoNftAddress);

    const displayDetail = (item) => {
        setActiveNFT(item);
        setShowDetail(true);
    }
    const mintNft = (item) => {
        const tierId = item.getIn(["tier", "id"]);
        const backendproof = item.get("proof");
        props.mintNft(props.web3, contract, props.accAddress, tierId, backendproof.toJS(), props.nftCollection, props.gasType);

    }

    const nftsToClaim = props.nftCollection.filter((item)=> !props.mintedNfts.includes(item.getIn(["tier", "id"])));

    return (
        <Container className='mt-4'>
            {(props.isLoading || props.isLoadingMinted) && <div className="text-center my-3"><Spinner size="sm" variant="secondary" animation="grow"/></div>}
            <div className='h5 text-light-2'>Available to Mint</div>
            <hr />
            <Row className="nft-list">
                {nftsToClaim.map((item, i) => {
                    return <Col xs={6} sm={6} md={2} key={i}>
                        <div className={`${props.themeMode === "dark"? "bg-dark" : "bg-beige border shadow-lg"} rounded p-2`}>
                            <img className='rounded' width="100%" src={item.getIn(["nft", "url"])} alt="nft" />
                            <div>
                                {item.hasIn(["tier", "tags"]) && item.getIn(["tier", "tags"]).map((tag, i)=> <Badge className="mt-2 me-2">{tag}</Badge>)}
                                {item.hasIn(["tier", "xp"]) && Number(item.getIn(["tier", "xp"])) > 0 && <Badge className="mt-2 me-2">XP {item.getIn(["tier", "xp"])}</Badge>}
                            </div>
                            <div className='d-grid mt-2'>
                                <Button size="sm" variant="light" onClick={() => displayDetail(item)}>View Details</Button>
                            </div>
                            <div className='d-grid mt-2'>
                                <Button disabled={item.get("mintExpired") === true} size="sm" variant="primary" onClick={()=> mintNft(item)}>
                                    Mint
                                    {item.get("mintExpired") && <small className="ms-2"><i className='fas fa-warning me-1'/>Expired</small>}
                                </Button>
                            </div>
                        </div>
                    </Col>
                })}
                {!props.isLoading && nftsToClaim.size ===0 && <div className='text-light-1'>You dont' have any NFT to claim!</div>}
            </Row>

            <div className='h5 text-light-2 mt-4'>Your Collection</div>
            <hr />
            <Row className='nft-list'>
                {props.mintedNfts.map((tierId, i) => {
                    const item = props.nftCollection.find((el)=> el.getIn(["tier", "id"]) === tierId);
                    if(!item) {
                        return null;
                    }
                    return <Col xs={6} sm={6} md={2} key={i}>
                        <div className={`${props.themeMode === "dark"? "bg-dark" : "bg-beige border shadow-lg"} rounded p-2`}>
                            <img className='rounded' width="100%" src={item.getIn(["nft", "url"])} alt="nft" />
                            <div>
                                {item.hasIn(["tier", "tags"]) && item.getIn(["tier", "tags"]).map((tag, i)=> <Badge className="mt-2 me-2">{tag}</Badge>)}
                                {item.hasIn(["tier", "xp"]) && Number(item.getIn(["tier", "xp"])) > 0 && <Badge className="mt-2">XP {item.getIn(["tier", "xp"])}</Badge>}
                            </div>
                            <div className='d-grid mt-2'>
                                <Button size="sm" variant="light" onClick={() => displayDetail(item)}>View Details</Button>
                            </div>
                            <div className='d-grid mt-2'>
                                <Button size="sm" variant="primary" disabled>Redeem</Button>
                            </div>
                        </div>
                    </Col>
                })}
                {!props.isLoadingMinted && props.mintedNfts.size === 0 &&  <div className='text-light-1'>You haven't claimed any NFT!</div>}
            </Row>
            {/* <hr className='mt-5'/>
            <div className="small">
                <a href="" target="_blank">View Full Collection on Opensea <i className='fas fa-arrow-up-right-from-square'/></a><span className='ms-2 me-2 text-light-2'>|</span>
                <a href="" target="_blank">FAQs <i className='fas fa-arrow-up-right-from-square'/></a><span className='ms-2 me-2 text-light-2'>|</span>
                <a href="" target="_blank">Terms and Conditions <i className='fas fa-arrow-up-right-from-square'/></a>
            </div> */}
            
            {showDetail && <Detail
                show={showDetail !== null}
                nft={activeNFT}
                // lendAPY={getLendAPY(activeWithdrawToken.get("address"))}
                onHide={()=> setShowDetail(null)}
            />}
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        connected: state.get("connected"),
        themeMode: state.get("themeMode"),
        isLoading: state.getIn(["nftCollection", "isLoading"]),
        nftCollection: state.getIn(["nftCollection", "data"]),
        web3: state.get("web3"),
        paxoNftAddress: state.getIn(["contracts", "nft"]),
        accAddress: state.get("accAddress"),
        mintedNfts: state.getIn(["mintedCollection", "data"]),
        isLoadingMinted: state.getIn(["mintedCollection", "isLoading"]),
        gasType: state.getIn(["gasPrices", "active"])
    }
}

const mapDispatchToProps = dispatch => {
    return {
        mintNft: (web3, contract, address, tierId, proof, nftData, gasType) => dispatch(mintNft(web3, contract, address, tierId, proof, nftData, gasType)),
        fetchNftData: (contract, address, chainId) => dispatch(fetchNftData(contract, address, chainId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Rewards)