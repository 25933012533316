import {useState} from 'react';
import {connect} from 'react-redux';
import {Card, Row, Col, Button, Spinner, Badge} from 'react-bootstrap';

import {getTokenIcon, fixed2Decimals, toPrecisin, getUSDPrice, mulFixed, divFixed, decimal2Fixed, minusFixed} from '../../../utils';
import LendModal from '../../dashboard/components/LendModal';
import { fromJS } from 'immutable';

function LendingAssets(props) {
    const [activeToken, setActiveToken] = useState(null);
    let lendAssets = [];
    props.assets.filter((item)=> item.get("lendEnabled") === true).map((asset)=> {
        if (props.accountSnapshot.hasIn([asset.get("address"), "lendBalance"]) && Number(props.accountSnapshot.getIn([asset.get("address"), "lendBalance"])) > 0) {
            lendAssets.push(asset);
        }
    })

    const getAPY = (token) => {
        let apy = props.accountSnapshot.getIn([token.get("address"), "lendAPY"]);
        return apy ? <span><img src={getTokenIcon(token.get("symbol"))} height="14" alt=""/>{token.get("symbol")} {toPrecisin(apy, 2)}%</span> : "---" ;
    }

    const {data} = props;
    const getCurrentOverallStats = ()=> {
        let stats = fromJS({
            "totalInterest": 0,
            "totalSupplied": 0,
            "lastDayEarnings": 0,
            "sumAPY": 0,
            "assets": {}    
        });
        for (let i=0; i< lendAssets.length; i++) {
            const asset = lendAssets[i];
            const currentData = data.getIn([asset.get("address").toLowerCase(), "current"]);
            // let balance = props.accountSnapshot.getIn([asset.get("address"), "lendBalance"])
            let balanceCurrent = 0;
            let interest = 0;
            if(currentData === undefined) {
                balanceCurrent = props.accountSnapshot.getIn([asset.get("address"), "lendBalance"]);
            } else {
                let exchangeRate = props.accountSnapshot.getIn([asset.get("address"), "exchangeRateStored"]);
                balanceCurrent = divFixed(mulFixed(currentData.get("balanceTokens"), exchangeRate), decimal2Fixed(1, 18));
                interest = balanceCurrent - currentData.get("balance");
            }
            
            // const lendBalanceTokens = props.accountSnapshot.getIn([asset.get("address"), "lendBalanceTokens"]);
            // const lendBalanceTokens = currentData.get("balanceTokens");
            
            // let lastDayEarning = "---";
            // if (props.historicData.hasIn(["1", asset.get("address")])) {
            //     const lastDayExchangeRate =  props.historicData.getIn(["1", asset.get("address"), "exchangeRate"]);
            //     const lastDayLendBalance = divFixed(mulFixed(lendBalanceTokens, lastDayExchangeRate), decimal2Fixed(1, 18));
            //     // console.log("last day lend balance", asset.get("symbol"), lastDayLendBalance, lastDayExchangeRate, lendBalanceTokens, balanceCurrent, lastDayLendBalance);
            //     // lastDayEarning = Number(balanceCurrent) - Number(lastDayLendBalance);
            //     lastDayEarning = minusFixed(balanceCurrent, lastDayLendBalance);
            //     stats = stats.set("lastDayEarnings", stats.get("lastDayEarnings") + Number(getUSDPrice(
            //         props.accountSnapshot.getIn([asset.get("address"), "oraclePrice"]), lastDayEarning, asset.get("decimals"))
            //     ) );
            // }
           
            stats = stats.set("totalInterest", stats.get("totalInterest")+ Number(getUSDPrice(props.accountSnapshot.getIn([asset.get("address"), "oraclePrice"]), interest, asset.get('decimals'))));
            stats = stats.set("totalSupplied", stats.get("totalSupplied")+ Number(getUSDPrice(props.accountSnapshot.getIn([asset.get("address"), "oraclePrice"]), balanceCurrent, asset.get('decimals'))));
            stats = stats.setIn(["assets", asset.get("address"), "balanceCurrent"], balanceCurrent);
            stats = stats.setIn(["assets", asset.get("address"), "interest"], interest);
        }
        return stats;
    }
    const overallCurrentStats = getCurrentOverallStats();
    return (
        <div>
            {props.isLoading && <div className="text-center mt-2"><Spinner size="sm" variant="secondary" animation="grow"/></div>}
            {lendAssets.length > 0 && <Card body className="mt-4">
                        <div className="text-light-1">
                            <Badge bg={props.themeMode==="dark"? "black text-light-1" : "beige text-body border"}>Lending Portfolio</Badge>
                            <span className='mx-4'>|</span>
                            <span className="">Current Value</span>
                            <span className="ms-2  text-success">
                                $ {overallCurrentStats.get("totalSupplied").toFixed(2)}
                            </span>
                            <span className='mx-4'>|</span>
                            <span className="">Interest Earned</span>
                            <span className='ms-2 text-success'>
                                $ {overallCurrentStats.get("totalInterest").toFixed(2)}
                            </span>
                        </div>
                        <div className='token-list'>
                            {lendAssets.map((asset, j)=> {
                                if(data.getIn([asset.get("address").toLowerCase(), "current", "balanceTokens"]) <=0) {
                                    return null;
                                }
                                const stats = overallCurrentStats.getIn(["assets", asset.get("address")]);
                                return (
                                    <Row key={j} className="token-list-item">
                                        <Col xs={6} sm={6} md={3} >
                                            <div className="title">{asset.get("symbol")}</div>
                                           
                                            <div className="value">
                                                <img src={getTokenIcon(asset.get("symbol"))} alt="token"/>
                                                {fixed2Decimals(data.getIn([asset.get("address").toLowerCase(), "current", "balance"]), asset.get("decimals"), 2)}
                                            </div>
                                        </Col>
                                        <Col xs={6} sm={6} md={3}>
                                            <div className="title">Interest Earned</div>
                                            <div className="value">
                                                {stats.get("interest") > 0 ? <span>
                                                    <img src={getTokenIcon(asset.get("symbol"))} alt="token"/>
                                                    {fixed2Decimals(stats.get("interest"), asset.get("decimals"), 2)}</span> 
                                                    : 
                                                "---"}
                                            </div>
                                        </Col>
                                        <Col xs={6} sm={6} md={3}>
                                            <div className="title">Current Value</div>
                                            <div className="value text-success"> 
                                                $ {getUSDPrice(
                                                props.accountSnapshot.getIn([asset.get("address"), "oraclePrice"]),
                                                // props.accountSnapshot.getIn([asset.get("address"), "lendBalance"]),
                                                stats.get("balanceCurrent"),
                                                asset.get('decimals'))}
                                            </div>
                                        </Col>
                                        <Col xs={6} sm={6} md={2}>
                                            <div className="title">APY</div>
                                            <div className="value">
                                                {getAPY(asset)}
                                            </div>
                                        </Col>
                                        
                                        <Col xs={12} sm={12} md={1} className='text-end'>
                                            <Button onClick={()=> setActiveToken(asset)} variant="primary">Withdraw</Button>
                                        </Col>
                                    </Row>
                                )
                            })}
                        </div>
                {activeToken !==null && <LendModal
                    activeTab="Withdraw"
                    show={activeToken !== null}
                    token={activeToken}
                    lendAPY={getAPY(activeToken)}
                    onHide={()=> setActiveToken(null)}
                />}
            </Card>}
        </div>

    )
}

const mapStateToProps = state => {
    return {
        assets: state.get("assets"),
        accountSnapshot: state.get("accountSnapshot"),
        isLoading: state.getIn(["transactions", "isLoading"]),
        historicData: state.get("historicData"),
        themeMode: state.get("themeMode")
    }
}

export default connect(mapStateToProps)(LendingAssets);