import { useEffect, useState } from 'react';
import { Spinner, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import DetailsModal from './DetailsModal';
import moment from 'moment';
import { fetchLeaderboard } from '../../redux/actions';

function Leaderboard(props) {
    useEffect(()=> {
        if(props.data === null) {
            props.fetchLeaderboard();
        }
    }, [])

    const [activeIndex, setActiveIndex] = useState(null);
    return (
        <div className='container'>
            {props.isLoading && <div className='text-center mt-4'> <Spinner variant="secondary" animation='grow'/></div>}
            {!props.isLoading && props.data !== null && <div>
                <div className='mt-4 text-left mb-4 text-light-1'>
                    <span className='h4'>Leaderboard</span>  <small className=''>
                        ({moment.unix(props.data.getIn(["epoch", "startAt"])).utc().format("ll HH:mm A z")} to {moment.unix(props.data.getIn(["epoch", "endAt"])).utc().format("ll HH:mm A z")})
                    </small>
                </div>
                <div className='text-end text-light-1 mb-2'>
                    Last updated at: {moment.unix(props.data.getIn(['leaderboard', 'createdAt'])).format("ll hh:mm A")}
                </div>
                <Table className='text-light-1' bordered hover variant={props.themeMode}>
                    <thead>
                        <tr className='text-light-2'>
                            <th>Rank</th>
                            <th>Account</th>
                            {/* <th>Trades</th> */}
                            <th>PnL USD</th>
                            <th>ROI</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.getIn(["leaderboard", "data", "portfolios"]).map((row, i)=> {
                            return <tr className='cursor-pointer' key={i} onClick={()=> setActiveIndex(i)}>
                                <td>{i+1}</td>
                                <td>{row.get("address")}</td>
                                {/* <td>{row.getIn(["opened", "data"]).size + row.getIn(["closed","data"]).size}</td> */}
                                <td className={row.get("pnl") > 0 ? "text-success": row.get("pnl") === 0 ? "" : "text-danger"}>
                                    {row.get("pnl").toFixed(2)}
                                </td>
                                <td className={row.get("pnl") > 0 ? "text-success": row.get("pnl") === 0 ?  "" : "text-danger"}>
                                    {row.get("roi").toFixed(2)}%
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table>
            </div>} 
            {activeIndex !== null && <DetailsModal 
                show={activeIndex !== null}
                assets={props.assets}
                currentPrices={props.data.getIn(["leaderboard", "data", "marketCurrentPrices"])}
                row={props.data.getIn(["leaderboard", "data", "portfolios" ]).get(activeIndex)}
                onHide={()=> setActiveIndex(null)}
                activeIndex={activeIndex}
                themeMode={props.themeMode}
            />}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        assets: state.get("assets"),
        themeMode: state.get("themeMode"),
        isLoading: state.getIn(["leaderboard", "isLoading"]),
        data: state.getIn(["leaderboard", "data"]),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchLeaderboard: ()=> dispatch(fetchLeaderboard())
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);