import { fromJS } from 'immutable';
import {useState} from 'react';
import {Modal, Row, Col, Button} from 'react-bootstrap';

const terms = [
    {"id": "t1" , "text": "I am not a citizen or resident of, or person subject to jurisdiction of, or located in, Cuba, Democratic People’s Republic of North Korea, Islamic Republic of Iran, Syria, the Crimea or Sevastopol, the People Republic of China (excluding Hong Kong, Macao and Taiwan), the United States of America (including its territories: American Samoa, Guam, Puerto Rico, the Northern Mariana Islands and the U.S. Virgin Islands), and shall not use or access WeFi while in any of the above territories."},
    {"id": "t2" , "text": "I am not subject to any sanctions administered or enforced by any country, government or international authority, and that I am not acting in the interests of such persons."},
    {"id": "t3" , "text": "I understand that if I fail to maintain sufficient collateral when using the WeFi protocol, my credit account(s) may be liquidated, in which case a penalty may be charged by the protocol."},
    {"id": "t4" , "text": "I acknowledge that the WeFi app and related software may have technical issues or may be vulnerable to hacks and exploits which may result in complete loss of my funds."}
]

export default function TermsModal(props) {
    const [selectedIds, setSelectetdIds] = useState([]);
    const [accepted, setAccepted] = useState(false);

    const onTermClick = id => {
       let data = fromJS(selectedIds).toJS();
       if (data.includes(id)) {
        data = data.filter((j)=> j !== id);
       }  else {
        data.push(id);
       }
       setSelectetdIds(data);
    }
    return(
        <Modal
            size='lg'
            show={props.show}
            onHide={()=> props.onHide()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            contentClassName={props.themeMode === "dark" ? "bg-black-modal": ""}
        >
            <Modal.Header className="position-relative border-0">
                {/* <Modal.Title id="contained-modal-title-vcenter">
                    Terms &amp; Conditions
                </Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                <div className='px-4'>
                    <div className='h6 text-light-1'>
                        Terms &amp; Conditions
                    </div>
                    <div className='mt-3 text-light-1  small'>By accessing or using WeFi Dapp, I agree that :</div>
                    <div className='terms-lines mt-3' style={{
                        maxHeight: "220px",
                        overflowY: "scroll",
                        border: "1px solid darkgrey",
                        borderRadius: "3px",
                        padding: "10px 10px"
                    }}>
                        <ul>
                            {terms.map((term, i)=> {
                                return <li className='small text-light-1 mt-2' key={i}>
                                    {term.text}
                                </li>
                            })}
                        </ul>
                    </div>
                    <div  className='mt-3 cursor-pointer text-light-1' onClick={()=> setAccepted(!accepted)}>
                        <i className={`me-2 fa fa-${accepted ? "check-square text-secondary": "square"} `}></i>
                        I have read and agree to the Terms and Conditions.
                    </div>
                     <div className="mt-3 mb-2">
                        <Button size="xl" onClick={()=> {
                            if(accepted) {
                                props.onHide();
                            }
                        }} disabled={!accepted} className="">
                            <span className="px-5">Continue</span>
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    ) 
}

