import { fromJS } from "immutable";
import { useState } from "react";
import { Card, Col, Row, Table, Button } from "react-bootstrap";
import { fixed2Decimals, getTokenIcon, getUSDPrice, mulFixed, toPrecisin } from "../../../utils";
import BorrowModal from '../../dashboard/components/BorrowModalV2';

export default function OpenPositions({data, assets, accountSnapshot}) {
    const [activeToken, setActiveToken] = useState(null);

    const borrowAssets = assets.filter((item)=> item.get("borrowEnabled"));

    const getBorrowBalances = () => {
        let totalOutstanding = 0;
        let totalBorrowBalance = 0;
        let totalBorrowAmount = 0;
        let totalRepaid = 0;
        let assetsBorrowBalance = fromJS({});
        borrowAssets.forEach((asset) => {
            assetsBorrowBalance = assetsBorrowBalance.set(
                asset.get("address").toLowerCase(), 
                fromJS({
                    "borrowBalance": data.getIn([asset.get("address").toLowerCase(), "current", "balance"]),
                    "borrowAmount": data.getIn([asset.get("address").toLowerCase(), "current", "borrow"]),
                    "borrowOutstanding": accountSnapshot.getIn([asset.get("address"), "borrowBalance"])
                })
            );
            totalBorrowBalance = totalBorrowBalance + Number(getUSDPrice(
                accountSnapshot.getIn([asset.get("address"), "oraclePrice"]),
                data.getIn([asset.get("address").toLowerCase(), "current", "balance"]),
                asset.get("decimals")
            ))

            totalBorrowAmount= totalBorrowAmount + Number(getUSDPrice(
                accountSnapshot.getIn([asset.get("address"), "oraclePrice"]),
                data.getIn([asset.get("address").toLowerCase(), "current", "borrow"]),
                asset.get("decimals")
            ))

            totalOutstanding = totalOutstanding + Number(getUSDPrice(
                accountSnapshot.getIn([asset.get("address"), "oraclePrice"]),
                accountSnapshot.getIn([asset.get("address"), "borrowBalance"]),
                asset.get("decimals")
            ));
            totalRepaid = totalRepaid + Number(getUSDPrice(
                accountSnapshot.getIn([asset.get("address"), "oraclePrice"]),
                data.getIn([asset.get("address").toLowerCase(), "current", "repaid"]),
                asset.get("decimals")
            ));
        });

        return {totalOutstanding, totalBorrowBalance, totalBorrowAmount, totalRepaid, assetsBorrowBalance}
    }

    const getAPY = (address) => {
        let apy = accountSnapshot.getIn([address, "borrowAPY"]);
        return apy ? `${toPrecisin(apy, 4)}%` : "---" ;
    }

    const {totalOutstanding, totalBorrowBalance, totalBorrowAmount, totalRepaid, assetsBorrowBalance} = getBorrowBalances();
    const totalInterest = totalOutstanding > 0 ? totalOutstanding - totalBorrowBalance : 0;
    const borrowKeys = assetsBorrowBalance.keySeq().toArray().filter((address)=> Number(assetsBorrowBalance.getIn([address, "borrowOutstanding"]))> 0);
    return <Card body className="mt-4">
        <div className="fs-6">Open Loan Positions ({borrowKeys.length})</div>
        <div className="token-list">
            {/* {props.isLoading && <div className="text-center mt-2"><Spinner size="sm" variant="secondary" animation="grow"/></div>} */}
            <Row className="token-list-item " style={{position: "relative"}}>
                {/* <Col>
                    <div className="title">Total Downpayment</div>
                    <div className="value">$ {overallCurrent.get("totalDownpayment").toFixed(2)}</div>
                </Col> */}
                
                <Col>
                    <div className="title">Total Borrow</div>
                    {/* <div className="value">{fixed2Decimals(overallCurrent.get("totalBorrow"), borrowAsset.get("decimals"), 2)} {borrowAsset.get("symbol")}</div> */}
                    <div className="value">$ {totalBorrowAmount.toFixed(2)}</div>

                </Col>
                <Col>
                    <div className="title">Total Repaid</div>
                    {/* <div className="value">{fixed2Decimals(overallCurrent.get("totalBorrow"), borrowAsset.get("decimals"), 2)} {borrowAsset.get("symbol")}</div> */}
                    <div className="value">$ {totalRepaid.toFixed(2)}</div>

                </Col>
                <Col>
                    <div className="title">Interest Payable</div>
                    <div className="value">
                        {/* {fixed2Decimals(interest, borrowAsset.get("decimals"), 2)} {borrowAsset.get("symbol")} */}
                        $ {totalInterest.toFixed(2)}
                    </div>
                </Col>
                <Col>
                    <div className="title">Total Outstanding</div>
                    {/* <div className="value">{borrowBalanceDisplay} {borrowAsset.get("symbol")}</div> */}
                    <div className="value">
                        {/* <Item title="Total Outstanding"
                            value={totalBorrowBalance.toFixed(2)}
                            isPrice={true}
                            items={assetsBorrowBalance}
                        /> */}
                        $ {totalOutstanding.toFixed(2)}
                    </div>
                </Col>
                
                <Col md={1} className="text-end">
                    <div className='d-grid'>
                        <Button variant="primary" onClick={()=> setActiveToken(fromJS({
                            targetToken: assets.find((item)=> item.get("buyEnabled")),
                            borrowAssets: assets.filter((item)=> item.get("borrowEnabled"))
                        }))}>Repay</Button>
                    </div>
                </Col>
            </Row>
        </div>

        {borrowKeys.length > 0 &&  <div className='table tx-table mt-4'>
            <Table size="lg"  className='text-light-2 table-lg'>
                <tbody>
                    <tr>
                        <td>Vault</td>
                        <td>Borrowed</td>
                        {/* <td>Downpayment</td> */}
                        <td>Repaid</td>
                        <td>Interest Payable</td>
                        <td>Outstanding</td>
                    </tr>
                    {borrowKeys.map((address, i)=> {
                        const item = borrowAssets.find( (item)=> item.get("address").toLowerCase() === address);
                        const balance = Number(fixed2Decimals( assetsBorrowBalance.getIn([address, "borrowBalance"]), item.get("decimals")));
                        const outStanding = Number(fixed2Decimals( assetsBorrowBalance.getIn([address, "borrowOutstanding"]), item.get("decimals")));
                        const assetInterest = outStanding - balance;
                        const icon = <img height="14" src={getTokenIcon(item.get("symbol"))} alt="token"/>
                        return <tr key={i}>
                            <td>
                                {icon} {item.get("symbol")}
                            </td>
                            <td>
                                {/* <Item
                                    title="Borrowed"
                                    value={currentData.get("totalBorrow").toFixed(2)}
                                    isPrice
                                    items={currentData.get("borrow")}
                                /> */}
                                {icon} {fixed2Decimals(data.getIn([address, "current", "borrow"]), item.get("decimals"), 2)}
                            </td>
                            {/* <td>
                                <Item
                                    title="Downpayment"
                                    value={currentData.get("totalDownpayment").toFixed(2)}
                                    isPrice
                                    items={currentData.get("downpayment")}
                                />
                            </td> */}
                             <td>
                                {/* <Item
                                    title="Repaid"
                                    value={currentData.get("totalRepaid").toFixed(2)}
                                    isPrice
                                    items={currentData.get("repaid")}
                                /> */}
                                {icon} {fixed2Decimals(data.getIn([address, "current", "repaid"]), item.get("decimals"), 2)}
                            </td>
                            <td>
                                {assetInterest > 0 ? <span>
                                    {/* {fixed2Decimals(assetInterest, borrowAsset.get("decimals"), 2)} {borrowAsset.get("symbol")} */}
                                    {icon} {assetInterest.toFixed(2)}
                                </span> : "---"}
                            </td>
                            <td>
                                {icon} {outStanding < 0 ? "0" : `${outStanding.toFixed(2)}`}
                            </td>
                        </tr>
                    })}
                </tbody>
            </Table>
        </div>}
        {activeToken !==null && <BorrowModal
                repayOnly={true}
                activeTab="Repay"
                show={activeToken !== null}
                tokens={activeToken}
                getAPY={(address) => getAPY(address)}
                onHide={()=> setActiveToken(null)}
            />}
    </Card>
}