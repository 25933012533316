import {connect} from 'react-redux';
import Web3 from "web3";
import {Card} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { pTokenABI } from "../../../utils/abi"
import { getContract, getUSDPrice } from '../../../utils';
import { tokens, chains } from './data/data';
import { Link } from 'react-router-dom';

function CrossChainBalances(props) {
    const [data, setData] = useState({});

    const getValue = async (accAddress) => {
        const resp = {
        totalUSD: 0,
        assests: [], //symbol
        chains : [],
        assetBalances: {}
        }
        for(let i=0; i<chains.length; i++) {
            const web3 = new Web3(chains[i].rpc);
            resp.assetBalances[chains[i].name] = [];
            for(let j=0; j<tokens.length; j++) {
                const tokenContract = getContract(web3, pTokenABI, tokens[j][chains[i].chainId])
                const balance = await tokenContract.methods.balanceOf(accAddress).call();
                if (Number(balance) > 0) {
                    if(!resp.chains.includes(chains[i].name)){
                        resp.chains.push(chains[i].name);
                    } 
                    if(!resp.assests.includes(tokens[j].symbol)) {
                        resp.assests.push(tokens[j].symbol);
                    }
                    // resp.assests.push(tokens[j][chains[i].chainId]);
                    resp.totalUSD += Number(getUSDPrice(
                        tokens[j].oraclePrice,
                        balance,
                        tokens[j].decimal
                    ));
                    const obj = {};
                    obj['asset'] = tokens[j].symbol;
                    obj['balance'] = balance;
                    obj['decimal'] = tokens[j].decimal;
                    resp.assetBalances[chains[i].name].push(obj);
                }
                
            }
        }
        setData(resp);
    }

    useEffect(() => {
        getValue(props.accAddress);
    }, [props.accAddress]);

    const shouldShow = ()=> {
        if (!props.connected || !data.hasOwnProperty("totalUSD") || data.totalUSD === 0) {
            return false;
        }
        else if(data.chains.length === 1 && data.chains.includes("polygon")) {
            return false;
        } 
        else {
            return true;
        }
    }

        if(shouldShow()) {
            return  <Card body className="mt-4">
                <div className="text-light-2 fs-4">You have 
                    <span className='ms-2 text-success'>${data.totalUSD.toFixed(2)}</span> worth  
                    <span className='ms-2 text-success'>{data.assests.map((s)=> s.toUpperCase()).join(", ")}</span> across 
                    <span className='ms-2 text-success'>{data.chains.length}</span> networks.
                </div>
                <div className="text-light-2 fs-5">
                    <Link style={{textDecoration: "underline"}} to="bridge" className='text-light-2 cursor-pointer text-underline'>Transfer</Link> and you can lend & earn interest.
                </div>
                {/* <Button variant="primary" onClick={() => setOpen(true)}>
                    Bridge
                </Button> */}
                {/* <Card className="mt-5"> */}
                {/* <hr/> */}
                {/* <div className='table tx-table'  style={{maxHeight: "250px", overflowX: "auto"}}>
                    <Table size="sm" className='text-light-2'>
                        <tbody>
                            {chains.map((row, i)=> <tr key={i}>
                                {data.assetBalances[row.name].length > 0 && <>
                                    <td>
                                        <Badge bg={props.theme}>
                                            {row.name}
                                        </Badge>
                                    </td>
                                    <td>
                                        {data.assetBalances[row.name].map((asset, i) => {
                                            return <div key={i}>
                                            <img src={getTokenIcon(asset.asset)} height="18" alt="" className='me-1'/>
                                            {fixed2Decimals(asset.balance , asset.decimal, 4)}
                                            <br/>
                                        </div>
                                        })}
                                    </td>
                                </>}
                                
                            </tr>)}
                        </tbody>
                    </Table>
                </div> */}
            {/* </Card> */}
        </Card>
    }else {
        return null;
    }
}

const mapStateToProps = state => {
    return {
        connected: state.get("connected"),
        assets: state.get("assets"),
        accountSnapshot: state.get("accountSnapshot"),
        accAddress : state.get("accAddress"),
        theme: state.get("themeMode")
    }
}

export default connect(mapStateToProps, {})(CrossChainBalances);