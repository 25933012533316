import BigNumber from 'bignumber.js';
import moment from 'moment';
import { useEffect } from 'react';
import { Table, Badge, Spinner, Card } from 'react-bootstrap';
import {connect} from 'react-redux';
import { fetchTransactions } from '../../../redux/actions';
import { fixed2Decimals, getTokenIcon } from '../../../utils';

function Transactions(props) {

    useEffect(()=> {
        if(props.data.size ===0) {
            props.fetchTransactions(
                props.activeChain.get('shortName'),
                props.accAddress
            );
        }
    }, [])

    const getEventData = event => {
        const type = event.get("eventName");
        const token = getToken(event.get("eventAddress"));
        const data = JSON.parse(event.get("data"));
        switch(type) {
            case "Redeem": 
                return <div>
                    <img src={getTokenIcon(token.get("symbol"))} height="18" alt="" className='me-1'/>
                    {fixed2Decimals(data.redeemAmount, token.get("decimals"))}
                </div>
            case "Mint":
                return <div>
                    <img src={getTokenIcon(token.get("symbol"))} height="18" alt="" className='me-1'/>
                    {fixed2Decimals(data.mintAmount, token.get("decimals"))}
                </div>;
            case "RepayBorrow":
                return <div>
                    <img src={getTokenIcon(token.get("symbol"))} height="18" alt="" className='me-1'/>
                    {fixed2Decimals(data.repayAmount, token.get("decimals"))}
                </div>;
            case "Borrow":
                return <div>
                    <img src={getTokenIcon(token.get("symbol"))} height="18" alt="" className='me-1'/>
                    {fixed2Decimals(data.borrowAmount , token.get("decimals"))}
                </div>;
            case "LiquidateBorrow":
                const seizeToken = props.assets.find((t) => t.get("address").toLowerCase() === data.cTokenCollateral.toLowerCase());
                const exchangeRate = props.accountSnaphot.getIn([seizeToken.get("address"), "exchangeRateStored"]);
                let seizeAmount = new BigNumber(data.seizeTokens).multipliedBy(new BigNumber(exchangeRate)).dividedBy(Math.pow(10, 18)).toFixed(0);
                return <div>
                    Borrow Repaid:&nbsp;
                    <img src={getTokenIcon(token.get("symbol"))} height="18" alt="" className='me-1'/>
                    {fixed2Decimals(data.repayAmount , token.get("decimals"))}
                    <br/>
                    Collateral Seized: <img src={getTokenIcon(seizeToken.get("symbol"))} height="18" alt="" className='me-1'/>
                    {fixed2Decimals(seizeAmount, seizeToken.get("decimals"))}
            </div>
            case "SwapAndSettle":
                const borrowToken = props.assets.find((t) => t.get("address").toLowerCase() === data.borrowedAsset.toLowerCase());
                return <div>
                    <img src={getTokenIcon(token.get("symbol"))} height="18" alt="" className='me-1'/>
                    {fixed2Decimals(data.numTokensAmount , token.get("decimals"))}
                    <br/>
                    <img src={getTokenIcon(borrowToken.get("symbol"))} height="18" alt="" className='me-1'/>
                    {fixed2Decimals(data.repayAmount, borrowToken.get("decimals"))}
                    <Badge bg={props.theme} className="ms-2"><span className='text-light-2'>Borrow Repaid</span></Badge>
                    <br/>
                    <img src={getTokenIcon(borrowToken.get("symbol"))} height="18" alt="" className='me-1'/>
                    {fixed2Decimals(data.receivedAmount, borrowToken.get("decimals"))}
                    <Badge bg={props.theme} className="ms-2"><span className='text-light-2'>Received</span></Badge>
                </div>
            default:
                return ""
        }
    }

    const getToken= address => {
        return props.assets.find((t)=> t.get("address").toLowerCase() === address.toLowerCase());
    }

    return  <Card body className='mt-4'>
        <div className='fs-6'>
            Transactions({props.data.size})
            <i className='fa fa-refresh ms-2 text-secondary cursor-pointer' onClick={()=> props.fetchTransactions(props.activeChain.get('shortName'), props.accAddress)}></i>
        </div>
        <hr/>
        <div className='table tx-table'  style={{maxHeight: "250px", overflowX: "auto"}}>
            {props.isLoading ? <Spinner variant="secondary" animation="grow"/> : <Table size="lg" className='text-light-2'>
                <tbody>
                    {props.data.map((row, i)=> <tr key={i}>
                        <td>
                            <Badge bg={props.theme}>
                                <a
                                    className='text-light-2'
                                    rel="noreferrer"
                                    href={`${props.activeChain.get("webURL")}/tx/${row.get("transactionHash")}`}
                                    target="_blank" 
                                >
                                    {row.get("eventName")}
                                    <i className="fa fa-up-right-from-square ms-1"/>
                                </a>
                            </Badge>
                        </td>
                        <td>
                            {getEventData(row)}
                        </td>
                        <td>
                            {moment.unix(row.get("timestamp")).format("ll hh:mm A")}
                        </td>
                    </tr>)}
                </tbody>
            </Table>}
        </div>

    </Card>
            
}   

const mapStateToProps = state => {
    return {
        accountSnaphot: state.get("accountSnapshot"),
        accAddress: state.get('accAddress'),
        theme: state.get("themeMode"),
        assets: state.get("assets"),
        activeChain: state.get("supportedChains").find((chain)=> chain.get("chainId") === state.get("chainId")),
        isLoading: state.getIn(["transactions", "isLoading"]),
        data: state.getIn(["transactions", "data"])
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchTransactions: (network, account)=> dispatch(fetchTransactions(network, account)) 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);