import {useState} from 'react';
import {connect} from 'react-redux';
import {Row, Col, Button, Alert, DropdownButton, Dropdown} from 'react-bootstrap';
import { decimal2Fixed, fixed2Decimals, getContract, getTokenIcon } from '../../../../utils';
import { repayBorrow } from '../../../../redux/actions';
import { pTokenABI } from '../../../../utils/abi';
import ApprovalModal from '../ApprovalModal';

function Repay(props) {
    const [showApprove, setShowApprove] = useState(false);
    const [sliderBalance, setSliderBalance] = useState(0);
    const token = props.token;
    let repayBalance = 0;
    if ( props.snapshot.hasIn([token.get("address"), "borrowBalance"]) ) {
        repayBalance = Number( fixed2Decimals(props.snapshot.getIn([token.get("address"), "borrowBalance"]), token.get("decimals")) );
    }

    let walletBalance = 0;
    if ( props.uTokenMeta.hasIn([token.get("uAddress"), "balance"]) ) {
        walletBalance = Number( fixed2Decimals(props.uTokenMeta.getIn([token.get("uAddress"), "balance"]), token.get("decimals")) );
    }


    const repay = ()=> {
        const contract = getContract(props.web3, pTokenABI, token.get("address"));
        props.repayBorrow(
            props.web3,
            contract,
            props.accAddress,
            decimal2Fixed(sliderBalance, token.get("decimals")),
            props.gasType
        );
        props.onHide();
    }

    return(
        <div>
            <Row className='align-items-center'>
                <Col>
                    <DropdownButton
                        variant="primary"
                        title={token.has("symbol") ? <span>
                            Repay Borrow {token.get("symbol")} <img height="16" src={getTokenIcon(token.get("symbol"))} alt="token"/>
                        </span> : ""}
                    >
                        {props.borrowAssets.map((item, i)=> {
                            return (
                                <Dropdown.Item onClick={()=> {
                                    setSliderBalance(0);
                                    props.onBorrowTokenChange(item);
                                }} key={i} active={item.get("address") === token.get("address")}>
                                    {item.get("symbol")}<img src= {getTokenIcon(item.get("symbol"))} alt="token" height="20" className='ms-2'/>
                                </Dropdown.Item>
                            )
                        })}
                    </DropdownButton>
                </Col>
                <Col className='small'>
                    <div className='text-light-2 fw-bold'>Borrow Balance</div>
                    <img height="16" className='me-2' src={getTokenIcon(token.get("symbol"))} alt={token.get("symbol")}/>
                    {repayBalance}
                </Col>
            </Row>
            <hr/>
            <Row className="mb-4 align-items-center">
                <Col xs={7} className="border-end border-2">
                    <div className=""><small className='text-light-1'>Repay {token.get("symbol")}</small></div>
                    <div className="input-group mt-1 ">
                        <input
                            value={sliderBalance}
                            className="form-control"
                            onChange={(e)=> setSliderBalance(e.target.value)}
                            type="number"
                        />
                        <button onClick={()=> setSliderBalance(repayBalance)}className="btn btn-primary">Max</button>
                    </div>
                </Col>
                <Col>
                    <div><small className='text-light-1'> <i className="fa fa-wallet me-2" />{token.get("symbol")} Balance</small></div>
                    <div className="fw-bold mt-1">
                        <img height="20" className='me-2' src={getTokenIcon(token.get("symbol"))} alt={token.get("symbol")}/>
                        {walletBalance} 
                    </div>   
                </Col>
            </Row>
            {Number(sliderBalance) > repayBalance && <Alert variant="secondary" className="mb-3 small fw-bold">
                <small>Repay amount should not exceed borrow balance total. </small>
            </Alert>}
            {Number(sliderBalance) > walletBalance && <Alert variant="secondary" className="mb-3 small fw-bold">
                <small>
                    Insufficient wallet balance
                    <span className="ms-3 me-3 text-muted">|</span>
                    <i className="fa fa-wallet"/> {walletBalance} {token.get("symbol")}
                </small>
            </Alert>}
            {!showApprove && <div className='d-grid'>
                <Button
                    size="lg"
                    disabled={(sliderBalance <= 0 || sliderBalance > repayBalance || sliderBalance > walletBalance)}
                    onClick={()=> setShowApprove(true)}
                >
                    Repay
                </Button>
            </div>}

            {showApprove && <ApprovalModal
                desc={`Repay  ${sliderBalance} ${token.get("symbol")}`}
                show={showApprove}
                amount = {decimal2Fixed(sliderBalance, token.get("decimals"))}
                token = {token}
                onSuccess= {()=> repay()}
                onHide= {()=> setShowApprove(false)}
            />}

        </div>
    ) 
}

const mapStateToProps = (state, props) => {
    return {
        snapshot: state.get("accountSnapshot"),
        web3: state.get("web3"),
        accAddress: state.get("accAddress"),
        uTokenMeta: state.getIn(["balanceAndAllowances", "uTokens"]),
        gasType: state.getIn(["gasPrices", "active"])
    }
}

const mapDispatchToProps = dispatch => {
    return {
        repayBorrow: (web3, contract, accAddress, amount, gasType) => dispatch(repayBorrow(web3, contract, accAddress, amount, gasType)) 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Repay);