import { connect } from 'react-redux';
import { fixed2Decimals, getContract, getTokenIcon, toPrecisin } from '../utils';
import { Spinner} from 'react-bootstrap';
import { fetchAccountSnapshot, fetchNativeBalance, fetchTransactions } from '../redux/actions';
import { libABI } from '../utils/abi';
import TokenRewards from './rewards/components/TokenRewards';

function WalletBalances(props) {
    if(!props.connected) {
        return null;
    }
    const borrowAssets =  props.assets.filter((t)=> t.get("borrowEnabled"));

    const refresh = () => {
        const contract = getContract(props.web3, libABI, props.libAddress);
        props.fetchNativeBalance(props.web3, props.accAddress);
        props.fetchAccountSnapshot(contract, props.accAddress, props.assets.map((item)=> item.get("address")).toJS());
        props.fetchTransactions(props.activeChain.get('shortName'), props.accAddress);
    }
    return (
        <div className='text-md-end mb-4'>
            <div className='wallet-balances'>
                <span className='me-4 fw-normal'><i className='fa  fa-wallet text-secondary me-1'/>Wallet Balance</span>
                <span>
                    {props.isLoadingNativeBalance ? <Spinner size="sm"  animation="grow" variant="secondary"/> : toPrecisin(fixed2Decimals(props.nativeBalance, 18), 4)} MATIC
                </span>
                {props.isLoadingUSDCBalance ? <Spinner size="sm"  animation="grow" variant="secondary"/> : borrowAssets.map((token, i) => {
                    if (props.uTokenMeta.hasIn([token.get("uAddress"), "balance"]) ) {
                        return <span style={{wordWrap: "break-word"}} key={i}>
                            <span className='ms-4 me-4'>|</span>
                            <img src={getTokenIcon(token.get("symbol"))} height="14" alt={token.get("symbol")} />
                            &nbsp;{fixed2Decimals(props.uTokenMeta.getIn([token.get("uAddress"), "balance"]), token.get("decimals"), 2)}
                        </span>
                    } else {
                        return null;
                    }
                })}
                <span onClick={()=> refresh()} title="refresh" className='ms-4 px-1 py-0 rounded-3 btn btn-primary'><i className='mx-1 fa fa-refresh'/></span>
            </div>
            <div className='wallet-balances ms-2 d-none d-md-inline-flex d-lg-inline-flex d-xl-inline-flex'>
                <a href='https://medium.com/@paxofinance/how-to-guide-paxo-mainnet-alpha-9ec7303fa4ae' target='_blank' rel='noreferrer' className='text-light-1'><small>Help <i className="fas fa-external-link-alt"></i></small></a>
            </div>
            <div className='mt-2'>
                <TokenRewards />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        connected: state.get("connected"),
        web3: state.get("web3"),
        accAddress: state.get("accAddress"),
        libAddress: state.getIn(["contracts", "libABI"]),
        uTokenMeta: state.getIn(["balanceAndAllowances", "uTokens"]),
        assets: state.get("assets"),
        isLoadingNativeBalance: state.get("isLoadingNativeBalance"),
        nativeBalance: state.get("nativeBalance"),
        isLoadingUSDCBalance: state.getIn(["snapshot", "isLoading"]),
        activeChain: state.get("supportedChains").find((chain)=> chain.get("chainId") === state.get("chainId")),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchNativeBalance: (web3, address) => dispatch(fetchNativeBalance(web3, address)),
        fetchAccountSnapshot: (contract, accAddress, tokens) => dispatch(fetchAccountSnapshot(contract, accAddress, tokens)),
        fetchTransactions: (network, account)=> dispatch(fetchTransactions(network, account)) 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WalletBalances);