import {providers} from "web3modal";
import FrontierIcon from '../../images/frontier-wallet.svg';


export default function ConnectWallet(props) {
    return  <div>
        <div
            onClick={()=> props.onConnect()}
            style={{width: "300px", margin: "auto"}}
            className={`text-center cursor-pointer ${props.themeMode === "dark" ? "bg-dark": "light-connect"} px-2 py-4 rounded-3`}
        >
            <div className="text-light-1 fs-6 text-center">
                <img height="30" className="me-3"  alt="metamask" src={providers.METAMASK.logo}/>
                <img height="30" className="me-3"  alt="walletconnect" src={providers.WALLETCONNECT.logo}/>
                <img height="30" className="me-3"  alt="coinbase" src={providers.COINBASEWALLET.logo}/>
                <img height="30"  alt="frontier" src={FrontierIcon}/>
                <div className="mt-3">Connect your wallet</div>
            </div>
        </div>
        <div className="mt-4 small text-light-2" style={{width: "300px", margin: "auto"}}>
            <i className="fa fa-info-circle me-2"></i>By connecting to the wallet you confirm that you have read and agree to WeFi <a href="https://wefi.xyz/terms-of-service/" target="_blank" rel="noreferrer">Terms of Service</a>.
        </div>
    </div>
}