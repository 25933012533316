import moment from "moment";
import { Card, Table } from "react-bootstrap";
import { fixed2Decimals, getTokenIcon } from "../../../utils";

export default function ClosedPositions({data, assets}) {
    const closedMarkets = data.keySeq().toArray().filter((address)=> data.getIn([address, "closed"]).size > 0);
    return <Card body className="mt-4"> 
        <div className="fs-6">Closed Loan Positions ({closedMarkets.length})</div>
        {closedMarkets.length > 0 && <div className='table tx-table mt-4'>
            <Table size="lg" className='text-light-2'>
                <tbody>
                    <tr>
                        <td>Vault</td>
                        <td>Borrowed</td>
                        <td>Repaid</td>
                    </tr>
                    {closedMarkets.map((address, i)=> {
                        const asset = assets.find((item)=> item.get("address").toLowerCase() === address);
                        const icon = <img height="14" src={getTokenIcon(asset.get("symbol"))} alt="token"/>
                        return <tr key={i}>
                            <td>
                                {icon} {asset.get("symbol")}
                            </td>
                            <td>
                               {data.getIn([address, "closed"]).map((borrowItem, j)=> {
                                    return borrowItem.get("borrowHistory").map((item, k)=> {
                                        return <div key={`${j}-${k}`}>
                                        {icon} &nbsp;
                                        {fixed2Decimals(item.get("amount"), asset.get("decimals"))}
                                        <small className="ms-3">{moment.unix(item.get("timestamp")).format("ll")}</small>
                                    </div>
                                    })
                                })}
                            </td>
                            <td>
                               {data.getIn([address, "closed"]).map((borrowItem, j)=> {
                                    return borrowItem.get("repaidHistory").map((item, k)=> {
                                        return <div key={`${j}-${k}`}>
                                        {icon} &nbsp;
                                        {fixed2Decimals(item.get("amount"), asset.get("decimals"))}
                                        <small className="ms-3">{moment.unix(item.get("timestamp")).format("ll")}</small>
                                    </div>
                                    })
                                })}
                            </td>
                        </tr>
                    })}
                </tbody>
            </Table>
        </div>}
    </Card>
}