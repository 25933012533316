import {useState} from 'react';
import {Modal, Row, Col} from 'react-bootstrap';
import { getTokenIcon } from '../../../utils';
import Lend from './modal/Lend';
import Redeem from './modal/Redeem';
import {connect} from 'react-redux';
import Settings from '../../../utils/settings/Container';
import ConnectWallet from '../../../components/ConnectWallet';

function LendModal(props) {
    const [activeTab, setActiveTab] = useState(props.activeTab ? props.activeTab : "Supply");
   
    let tabs = ["Supply", "Withdraw"];
    const token = props.token;
    if (token.get("lendEnabled") === false) {
        tabs = ["Withdraw"];
    }

    const getTabContent = () => {
        if (!props.connected) {
            return <ConnectWallet
                onConnect={()=> props.onConnect()}
                themeMode={props.themeMode}
            /> 
        }
        if(activeTab === "Supply") {
            return <Lend
                onHide={()=> props.onHide()}
                token={props.token}
                lendAPY={props.lendAPY}
            />
        } else {
            return <Redeem
                onHide={()=> props.onHide()}
                token={props.token}
            />
        }
    }

    return(
        <Modal
            show={props.show}
            onHide={()=> props.onHide()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            contentClassName={props.themeMode === "dark" ? "bg-black-modal": ""}
        >
            <Modal.Header closeButton className="position-relative text-center border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    <div className='modal-header-token'>
                        <div className="icon">
                            <img src={getTokenIcon(token.get("symbol"))} alt={token.get("symbol")}/>
                        </div>
                        <div className='text-light-2 mt-2 fs-5'>{token.get("symbol")}</div>
                    </div>
                </Modal.Title>
                <Settings/>
            </Modal.Header>
            <Modal.Body>
                <Row className='mt-4 g-0 modal-tab'>
                    {tabs.map((tab, i)=> {
                        return (
                            <Col
                                onClick={()=> setActiveTab(tab)}
                                className={`modal-tab-item ${tab === activeTab ? "active" : ""}`}
                                key={i}
                            >
                                {tab} 
                            </Col>
                        ) 
                    })}
                </Row>
                <div className='p-4'>
                    {getTabContent()}
                </div>
            </Modal.Body>
        </Modal>
    ) 
}

const mapStateToProps = state => {
   return {
        connected: state.get("connected"),
        themeMode: state.get("themeMode")
   }
}   

export default connect(mapStateToProps, {})(LendModal);