import {useState} from 'react';
import SettingsModal from './SettingsModal';

export default  function Settings(props) {
    const [showGasModal, setGasModal] = useState(false); 
    return <span>
       <i className="fas fa-cog cursor-pointer text-light-2"  onClick={()=> setGasModal(!showGasModal) } />
       {showGasModal && <SettingsModal 
            show={showGasModal}
            onHide={()=> setGasModal(false)}
       />}
    </span>
}

