import {useState} from 'react';
import {connect} from 'react-redux';

import {Card, Row, Col, Button, Spinner} from 'react-bootstrap';
import {addFixed, fixed2Decimals, getRewardAPR, getTokenIcon, getUSDPrice, mul, toPrecisin} from '../../../utils';
import LendModal from './LendModal';
import { generateTimeline } from '../../portfolio/components/calc';
import LendingAssetsV2 from '../../portfolio/components/LendingAssetsV2';
import CrossChainBalances from './CrossChainBalances';

function LendAssets(props) {
    const [activeLendToken, setActiveLendToken] = useState(null);

    const getAPY = (token) => {
        let apy = props.accountSnapshot.getIn([token.get("address"), "lendAPY"]);
        return apy ? <span><img src={getTokenIcon(token.get("symbol"))} height="14" alt=""/>{token.get("symbol")} {toPrecisin(apy, 2)}%</span> : "---" ;
    }

    const getPoolSize = (address, decimals) => {
        if(props.accountSnapshot.hasIn([address, "totalSupply"])) {
            let supply = addFixed(props.accountSnapshot.getIn([address, "totalBorrowsCurrent"]), props.accountSnapshot.getIn([address, "totalCash"]));
            supply = getUSDPrice(props.accountSnapshot.getIn([address, "oraclePrice"]), supply, decimals);
            return `$ ${numFormatter(supply)}`;
        } else {
            return <Spinner size="sm" animation="grow" variant="secondary" />
        }  
    }

    const getUtilization = (address) => {
        const supply = addFixed(props.accountSnapshot.getIn([address, "totalBorrowsCurrent"]), props.accountSnapshot.getIn([address, "totalCash"]));
        // const cash  = props.accountSnapshot.getIn([address, "totalCash"]);
        const borrow =  props.accountSnapshot.getIn([address, "totalBorrowsCurrent"]);
        if (supply === undefined || borrow === undefined) {
            // return <Spinner size="sm" animation="grow" variant="secondary"/>
            return "---";
        } else if (Number(supply) === 0) {
            return "---";
        } else {
            return `${(((borrow)/supply)*100).toFixed(2)}%`
        }
    }

    const numFormatter = num => {
        if(num ==0) {
            return 0;
        } else if(num > 999 && num < 1000000){
            return (num/1000).toFixed(2) + 'K'; // convert to K for number from > 1000 < 1 million 
        }else if(num > 1000000){
            return (num/1000000).toFixed(2) + 'M'; // convert to M for number from > 1 million 
        }else if(num < 900){
            return num; // if value < 1000, nothing to do
        }
    }

    const rewardAPR = (token) => {
        let snapshot = props.accountSnapshot.get(token.get("address"));
        if (snapshot !== undefined) {
            let supplyRewardAPR = 0;
            let supplySpeed = snapshot.get("supplySpeed")
            let totalSupply = addFixed(snapshot.get("totalBorrowsCurrent"), snapshot.get("totalCash"));
            if(Number(supplySpeed) > 0 && Number(totalSupply > 0)) {
                let totalSupplyUSD = getUSDPrice(snapshot.get("oraclePrice"), totalSupply, token.get("decimals"));
                supplyRewardAPR = getRewardAPR(supplySpeed, 18, fixed2Decimals(props.wefiPrice, 8), 37565, Number(totalSupplyUSD));
            }
            return supplyRewardAPR > 0 ? <span>
                <img src={getTokenIcon("wefi")} alt="paxo" height="12" className='me-1' /> WEFI&nbsp;&nbsp;
                {supplyRewardAPR.toFixed(0)} %
            </span> : "---";
        }
       
    }
    const lendAssets = props.assets.filter((item)=> item.get("lendEnabled"));
    const {supplyData} = generateTimeline(props.assets, props.transactions, props.accountSnapshot);
    return (
        <div>
            {props.connected && <LendingAssetsV2  data = {supplyData}/>}
            <CrossChainBalances />
            <Card body className="mt-4">
                <Row>
                    <Col className="g-0">
                        <div className="fs-6">Lend to Earn</div>
                        <div className="small text-muted">Tokens you can lend to earn interest</div>
                    </Col>
                </Row>
                <div className="token-list">
                    {lendAssets.map((token, j)=> {
                        const markets = props.assets.filter((item)=> token.get("buyAssets").includes(item.get("address")));
                        return <Row key={j} className="token-list-item align-items-center">
                            <Col xs={6} sm={6} md={2}>
                                <div className="title">Pool</div>
                                <div className="value"><img src={getTokenIcon(token.get("symbol"))} alt="token"/>{token.get("symbol")}</div>
                            </Col>
                            <Col xs={6} sm={6} md={2}>
                                <div className="title">Markets</div>
                                <div>
                                    {markets.map((item, i)=> <img key={i} src={getTokenIcon(item.get("symbol"))} height="20" className='me-2' alt={item.get("symbol")} />)}
                                </div>
                                {markets.size === 0 && <div className='value'>
                                    ---
                                </div>}
                            </Col>
                            <Col xs={6} sm={6} md={2}>
                                <div className="title">TVL</div>
                                <div className="value">{getPoolSize(token.get("address"), token.get("decimals"))}</div>
                            </Col>
                            <Col xs={6} sm={6} md={2}>
                                <div className="title">Reward APR</div>
                                <div className="value">
                                    {rewardAPR(token)}
                                </div>
                            </Col>
                            <Col xs={6} sm={6} md={2}>
                                <div className="title">Lending Yield</div>
                                <div className="value">{getAPY(token)}</div>
                            </Col>
                            <Col xs={6} sm={6} md={1}>
                                <div className="title">Utilization</div>
                                <div className="value">
                                    {token.get("symbol").toLowerCase() === "wefi" ? "---": getUtilization(token.get("address"))}
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={1} className='d-grid'>
                                <Button variant="primary" className="action" onClick={()=> setActiveLendToken(token)}>Supply</Button>
                            </Col>
                        </Row>
                    })}
                </div>
                {activeLendToken !==null && <LendModal
                    show={activeLendToken !== null}
                    token={activeLendToken}
                    lendAPY={getAPY(activeLendToken)}
                    onHide={()=> setActiveLendToken(null)}
                    onConnect={props.onConnect}
                />}
            </Card>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        connected: state.get("connected"),
        assets: state.get("assets"),
        accountSnapshot: state.get("accountSnapshot"),
        transactions: state.getIn(["transactions", "data"]),
        wefiPrice: state.get("wefiPrice")
    }
}

export default connect(mapStateToProps, {})(LendAssets);