import {Route, Routes, useLocation} from  "react-router-dom";
import {useState, useEffect} from 'react';
import Web3 from "web3";
import Web3Modal, {providers} from "web3modal";
import {connect} from 'react-redux';
import WalletConnectProvider from '@walletconnect/web3-provider'
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import Lend from "./app/screens/lend/Lend";
import Borrow from "./app/screens/borrow/Borrow";
import Rewards from './app/screens/rewards/Rewards';
import Web3Analytics from 'analytics-web3';

import {updateAccount,
    fetchAccountSnapshot,
    resetTransaction,
    fetchNativeBalance,
    fetchWhiteLabelAccess,
    fetchTransactions,
    doWhiteList,
    fetchHistoricData,
} from "./app/redux/actions";

import  {getContract} from './app/utils';
import NavBar from './app/layout/NavBar';
import Portfolio from './app/screens/portfolio/Portfolio';
import { libABI } from "./app/utils/abi";
import TransactionModal from "./app/screens/dashboard/components/TransactionModal";
import { Button, Spinner } from "react-bootstrap";
import TermsModal from "./app/screens/TermsModal";
import WhitelistModal from "./app/screens/WhitelistModal";
import IntractAttribution from '@intract/attribution';
import Leaderboard from "./app/screens/tradingLeaderboard/Leaderboard";
import FrontierIcon from './images/frontier-wallet.svg';
import ConnectWallet from "./app/components/ConnectWallet";
import Bridge from "./app/screens/Bridge";
import moment from "moment";
import WeFiSwap from "./app/screens/WeFiSwap";
const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";

function App(props) {
    const [web3, setWeb3] = useState(null);
    const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: "2af333c0c57046ff8ba54601b8e04be9",
            rpc: {
                137: "https://matic-mainnet.chainstacklabs.com"
            }
          }
        },
        coinbasewallet: {
            package: CoinbaseWalletSDK, 
            options: {
              appName: "WeFi.xyz",
              infuraId: "2af333c0c57046ff8ba54601b8e04be9"
            }
        },
        "custom-frontier": {
            display: {
                logo: FrontierIcon,
                name: "Frontier",
                description: window.frontier ?  "Connect to your Frontier account" : "Download Frontier"
              },
            package: window.frontier ? window.frontier : {},
            options: {
            },
            connector: async (ProviderPackage, options) => {
                if (ProviderPackage.ethereum) {
                    const provider = ProviderPackage.ethereum;
                    await provider.enable();
                    return provider;
                } else {
                    window.open("https://www.frontier.xyz/download");
                    throw new Error("Frontier not installed");
                }
                
            }
        },
    };
    if (!window.ethereum) {
        providerOptions['custom-metamask'] = {
          display: {
            logo: providers.METAMASK.logo,
            name: 'MetaMask',
            description: 'Download MetaMask'
          },
          package: {},
          connector: async () => {
            window.open('https://metamask.io/download/');
            throw new Error('MetaMask not installed');
          }
        }
    }
    const [web3Modal, setWeb3Modal] = useState(new Web3Modal({
        // network: props.network, // optional
        cacheProvider: true, // optional
        providerOptions,
        theme: props.themeMode
    }));
    const [provider, setProvider] = useState(null);
    const [isSwitching, setSwitching] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [showWhitelistModal, setWhiteListModal] = useState(false);
   
    useEffect(()=> {
        if (web3Modal && web3Modal.cachedProvider && web3Modal.provider !=="") {
            onConnect();
        } else {
            setShowTermsModal(true);
            const web3 = getDefaultWeb3();
            const data = {
                web3, accAddress: NULL_ADDRESS, networkId: 137, chainId: 137
            }
            props.updateAccount(data);
            setAccountAndBalances(web3, NULL_ADDRESS);
            setWeb3(web3);
        }
        if(window.location.href.includes("alpha-app") || window.location.href.includes("beta.vaults")) {
            Web3Analytics.init({ appKey: 'fdcfc764a3b13348d49bee86a17057920aefdce5496a1ee79629305a05e42571', debug: false });
            IntractAttribution("6418990f25b191313b948723", {
                configAllowCookie: true
            });
        }
    } , [])

    useEffect(()=> {
        if(props.connected && props.accAddress !== "" && props.accessStatus === 3) {
            setWhiteListModal(true);
        }
    }, [props.connected, props.accessStatus])

    const getDefaultWeb3 = ()=> {
        return new Web3("https://polygon-rpc.com");
    }


    const getWeb3 = (provider)=> {
        const web3 = new Web3(provider);
        web3.eth.extend({
        methods: [
            {
                name: "chainId",
                call: "eth_chainId",
                outputFormatter: web3.utils.hexToNumber
            }
        ]
        });
        return web3;
    }

   

    const onConnect = async () => {
        let web3ModalInstance = web3Modal;
        if ((!web3ModalInstance || !web3ModalInstance.cachedProvider || web3ModalInstance.cachedProvider === "")){
            web3ModalInstance = new Web3Modal({
                // network: props.network, // optional
                cacheProvider: true, // optional
                providerOptions,
                theme: props.themeMode
            });
        }
        const provider = await web3ModalInstance.connect();
        if(window.location.href.includes("alpha-app") || window.location.href.includes("beta.vaults")) {
            Web3Analytics.walletProvider(provider);
        }
        await subscribeProvider(provider);
        await provider.enable();
        const web3 = getWeb3(provider);
        const accounts = await web3.eth.getAccounts();
        const networkId = await web3.eth.net.getId();
        const chainId = await web3.eth.chainId();
        setWeb3(web3);
        setProvider(provider);
        setWeb3Modal(web3ModalInstance);
        const data = {
            web3, accAddress:  accounts[0], networkId, chainId, connected: true, provider: provider
        }
        props.updateAccount(data);
        if (isChainValid(chainId)) {
            setAccountAndBalances(web3, data.accAddress);
            props.fetchWhiteLabelAccess(data.accAddress);
            setHistoricData(chainId);
        }
    }

    const setHistoricData = async (chainId) => {
        props.fetchHistoricData(chainId, 1);
        // props.fetchHistoricData(chainId, 30);
    }

    const setAccountAndBalances = (web3, accAddress) => {
        const contract = getContract(web3, libABI, props.libAddress);
        const tokens  = props.assets.map((item)=> item.get("address")).toJS();
        props.fetchAccountSnapshot(contract, accAddress, tokens);
        if(accAddress !== NULL_ADDRESS) {
            props.fetchNativeBalance(web3, accAddress);
            props.fetchTransactions("matic", accAddress);
        }
        
    }

    const subscribeProvider = async (provider) => {
        if (!provider.on) {
            return;
        }
        provider.on("accountsChanged", async (_accounts) => {
            // console.log("accounts changed", _accounts[0]);
           
            const web3 = getWeb3(provider);
            const chainId = await web3.eth.chainId();
            props.updateAccount({accAddress: _accounts[0], chainId}) 
            if(isChainValid(chainId)) {
                setAccountAndBalances(new Web3(provider), _accounts[0]);
                props.fetchWhiteLabelAccess(_accounts[0]);
            }
        });

        provider.on("chainChanged", async (_chainId) => {
            // const _networkId = await web3.eth.net.getId();
            const cId = Web3.utils.hexToNumber(_chainId);
            // console.log("chain changed", cId, "valid", isChainValid(cId));
            setTimeout(async ()=> {
                // wait for few seconds. Coinbase break patch
                const web3 = getWeb3(provider);
                const accounts = await web3.eth.getAccounts();
                props.updateAccount({chainId: cId, accAddress: accounts[0]});
                if(isChainValid(cId)) {
                    setAccountAndBalances(web3, accounts[0]);
                    props.fetchWhiteLabelAccess(accounts[0]);
                    setHistoricData(cId);
                }
                setSwitching(false);
            }, 3000)

        });

        provider.on("disconnect", () => {
            // console.log("Disconnected");
            resetAcoount();
        });
        
    };

    const isChainValid = (cId) => {
       return props.supportedChains.find((c)=> c.get("chainId") === cId) !== undefined;
    }

    const disconnect = async()=> {
        await web3Modal.clearCachedProvider();
        if(provider.close) {
            await provider.close();
        }
        resetAcoount();
    };

    const resetAcoount = async ()=> {
        props.updateAccount({web3: getDefaultWeb3(), accAddress: NULL_ADDRESS, networkId: 137, chainId: 137, connected: false, provider: null});
        setAccountAndBalances(getDefaultWeb3(), NULL_ADDRESS);
    }

    const switchNetwork = async () => {
        setSwitching(true);
        try {
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: web3.utils.toHex(137) }],
          });
        } catch (switchError) {
          // This error code indicates that the chain has not been added to MetaMask.
          if (switchError.code === 4902) {
            try {
              await provider.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: web3.utils.toHex(137),
                    chainName: "Polygon",
                    rpcUrls: ["https://matic-mainnet.chainstacklabs.com", "https://polygon-rpc.com/"],
                    blockExplorerUrls: ["https://polygonscan.com/"],
                  },
                ],
              });
            } catch (addError) {
              throw addError;
            }
          } else {
            setSwitching(false);
          }
        }
      };

    const isVaildChain = isChainValid(props.chainId);

    return (
        <div className={`dapp-container position-relative ${props.themeMode === "dark" ? "bg-black": ""}`}>
            <div className={`py-2 text-center ${props.themeMode === "dark" ? "bg-black text-white": "bg-white"}`}>
                <a className={`${props.themeMode === "dark" ? "text-white": ""} me-2`} href="https://blog.paxo.finance/defi-protocol-paxo-finance-rebrands-to-wefi-gears-up-for-first-product-release-aa1d8bde4a9b" target="_blank" rel="noreferrer">
                   Paxo Finance is now WeFi.
                </a>
                {/* <span>We are offering 0 trading fees on all transactions in {moment().format("MMMM")}.</span> */}
            </div>
            <NavBar
                onConnect = {() => onConnect()}
                onDisconnect={()=> disconnect()}
            />
            {/* <div className="section-bg"></div> */}
            <Routes>
                {isVaildChain && <Route path="/" props element={<Lend onConnect={()=> onConnect()}/>} />}
                {isVaildChain &&<Route path="/borrow" element={<Borrow onConnect={()=> onConnect()}/>} />}
                {isVaildChain && <Route path="portfolio" element={<Portfolio onConnect={()=> onConnect()}/>} />}
                {isVaildChain && <Route path="rewards" element={<Rewards onConnect={()=> onConnect()}/>} />}
                <Route path="bridge" element={<Bridge onConnect={()=> onConnect()}/>} />
                <Route path="swap" element={<WeFiSwap/>} />
            </Routes>
            {!["/bridge", "/swap"].includes(useLocation().pathname) && !isVaildChain && props.connected && <div className="mt-5" style={{minHeight: "70vh"}}>
                <h6 className="text-center text-light-1">Chain ID {props.chainId} is not supported.<br/><br/>Please switch to {props.supportedChains.map((a)=> a.get("name")).join(" / ")}  network.</h6>
                <div className="text-center mt-4">
                    <Button disabled={isSwitching} onClick={()=> switchNetwork()}>
                        Switch to Polygon Network <i className="fa fa-caret-right"/>
                        {isSwitching && <Spinner size="sm" className="ms-2" variant='secondary' animation="grow"/>}
                    </Button>
                </div>
            </div>}
           
            {props.transaction.get("isActive") === true && <TransactionModal
                refreshBalances={ async (newBlockNumber)=> {
                    // const currentBlockNumber= web3.eth.blockNumber
                    web3.eth.defaultBlock = newBlockNumber;
                    setAccountAndBalances(web3, props.accAddress);
                    setTimeout(()=> props.fetchTransactions("matic", props.accAddress), 3000);
                    setTimeout(()=> props.fetchTransactions("matic", props.accAddress), 6000);
                    setTimeout(()=> props.fetchTransactions("matic", props.accAddress), 9000);
                }}
                show={props.transaction.get("isActive") === true}
                onHide={()=> {
                    props.resetTransaction();
                }}
            />}
            <footer className="footer mt-5 py-3 text-muted text-center"> 
                <div className="container">
                    <a className="me-4" href="https://paxo.finance/terms-of-service/" target="_blank" rel="noreferrer">Terms of Service</a>
                    <a className="me-4" href="https://discord.gg/spJjdcrgYM" target="_blank" rel="noreferrer">Suggestions</a>
                    <a className="" href="https://discord.gg/QCWhrvfk9D" target="_blank" rel="noreferrer">Support</a>
                </div>
            </footer>
            <TermsModal
                show={showTermsModal}
                onHide={()=> setShowTermsModal(false)}
                themeMode={props.themeMode}
            />
            {showWhitelistModal && <WhitelistModal
                show={showWhitelistModal}
                onHide={()=> setWhiteListModal(false)}
                themeMode={props.themeMode}
                accAddress={props.accAddress}
                isProcessing= {props.processingWhitelist}
                accessStatus={props.accessStatus}
                doWhiteList={(address)=> props.doWhiteList(address)}
            />}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        network: state.get("network"),
        chainId: state.get("chainId"),
        connected: state.get("connected"),
        supportedChains: state.get("supportedChains"),
        libAddress: state.getIn(["contracts", "libABI"]),
        assets: state.get("assets"),
        transaction: state.get("transaction"),
        web3: state.get("web3"),
        accAddress: state.get("accAddress"),
        themeMode: state.get("themeMode"),
        accessStatus: state.getIn(["accessStatus", "status"]),
        processingWhitelist: state.getIn(["accessStatus", "processing"])
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateAccount: data => dispatch(updateAccount(data)),
        fetchAccountSnapshot: (contract, accAddress, tokens) => dispatch(fetchAccountSnapshot(contract, accAddress, tokens)),
        resetTransaction: ()=> dispatch(resetTransaction()),
        fetchNativeBalance: (web3, address) => dispatch(fetchNativeBalance(web3, address)),
        fetchWhiteLabelAccess: (address) => dispatch(fetchWhiteLabelAccess(address)),
        fetchTransactions: (network, address) => dispatch(fetchTransactions(network, address)),
        doWhiteList: address => dispatch(doWhiteList(address)),
        fetchHistoricData: (chainID, days) => dispatch(fetchHistoricData(chainID, days))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);