import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";

function WeFiSwap(props) { 
  
    return (
        <Container className="mt-4">
            <Row>
                <Col md={{span:4, offset: 4}}  sm={12}>
                    <iframe
                        width="100%"
                        title='Buy WeFi'
                        frameborder="0"
                        src={`https://syncswap.xyz/widget?overlay=true&dark=${props.theme === 'dark'}&outputCurrency=0x81E7186947fb59AAAAEb476a47daAc60680cbbaF`}
                        style={{
                            borderRadius: "32px",
                            overflowY: "auto",
                            backgroundColor: props.theme === 'dark' ? "#20213E": "white",
                            height: "600px"
                        }}
                    >
                    </iframe>
                </Col>
            </Row>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        connected: state.get("connected"),
        theme: state.get("themeMode")
    }
}

export default connect(mapStateToProps, {})(WeFiSwap);