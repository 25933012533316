import { useEffect } from 'react';
import {Modal, Row, Col, Button, Spinner, Badge} from 'react-bootstrap';
import { shortAddress } from '../utils';

export default function WhiteListModal(props) {
    useEffect(()=> {
        props.doWhiteList(props.accAddress);
    }, [])
    return(
        <Modal
            show={props.show}
            onHide={()=> props.onHide()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            contentClassName={props.themeMode === "dark" ? "bg-black-modal": ""}
        >
            <Modal.Header closeButton  className="position-relative text-center border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <div className='text-center'>
                    <img height="70" src={Trophy} alt="rewards"/>
                </div> */}
                <div className={`text-light-2 h5 mb-4 text-center`}>
                    <i className="fa fa-user"/> {shortAddress(props.accAddress)}
                </div>
                <div className='p-4 rounded-4 bg-dark'>
                    {props.isProcessing ? <div className='text-light-1 text-center'>
                        <Spinner variant="secondary" animation='grow'></Spinner>
                        <div className=' text-light-2 mt-3'>
                            Please wait, you are being whitelisted.
                        </div>
                    </div> : 
                    <div className='text-center text-light-2'>
                        {props.accessStatus === 1 && <div>
                            <i className='fas fa-check-circle text-success fa-3x'/> <br/><br/>
                            Your account is whitelisted.
                        </div>}
                        {props.accessStatus === 0 && <div>
                            <i className='fas fa-ban text-danger fa-3x'/> <br/><br/>
                            Your account could not be whitelisted.<br/>Please connect on <a target="_blank" href="https://discord.com/invite/a9MQpH7SMr">discord</a> for more details.
                        </div>}

                        {props.accessStatus === 2 && <div>
                            <i className='fas fa-warning text-warning fa-3x'/> <br/><br/>
                            Error while processing your request.<br/>Please try again or get in touch with us on <a target="_blank" href="https://discord.com/invite/a9MQpH7SMr">discord</a>.
                        </div>}
                        <div className='mt-4'>
                            <Button onClick={()=> props.onHide()}>Close</Button>
                        </div>
                    </div>}
                </div>
            </Modal.Body>
        </Modal>
    ) 
}
