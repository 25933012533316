import {Container} from 'react-bootstrap';

import WalletBalances from '../WalletBalances';
import BorrowAssets from '../dashboard/components/BorrowAssetsV2';

export default function Borrow(props) {
    return <Container>
        <WalletBalances />
        <BorrowAssets {...props}/>
    </Container>
}