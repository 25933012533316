import {useState, useEffect} from 'react';
import {Modal as BModal, Button, Spinner, Row, Col} from 'react-bootstrap';
import {connect} from 'react-redux';
import { getContract, areEqual, fixed2Decimals, getGasPrice, shortAddress } from '../../../utils';
import { pTokenABI } from '../../../utils/abi';

function ApprovalModal(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [txId, setTxId] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [loadingAllowance, setLoadingAllowance] = useState(true);

    useEffect(()=> {
        async function fetchAllowance (){
            const contract = getContract(props.web3, pTokenABI, props.token.get("uAddress"));
            const  amount =  await contract.methods.allowance(props.accAddress, props.token.get("address")).call();
            setLoadingAllowance(false);
            if(areEqual(amount, props.amount)) {
                props.onSuccess();
            }
        }
        fetchAllowance();
    }, []);

    const approve = async () => {
        const gasPrice = await getGasPrice(props.gasType);
        const contract = getContract(props.web3, pTokenABI, props.token.get("uAddress"));
        const amount = props.amount;
        setIsLoading(true);
        contract.methods.approve(props.token.get("address"), amount)
		.send({ from: props.accAddress, ...gasPrice})
		.on("transactionHash", (txId) => {
			setTxId(txId);
            checkTransaction(props.web3, txId);
		})
		.on("error", function (error) {
            setIsLoading(false);
            setIsSuccess(false);
            setIsCompleted(true);
		})
    }

    const checkTransaction = (web3, txId) => {
        web3.eth.getTransactionReceipt(txId, (err, response) => {
            if (response) {
                setIsLoading(false);
                setIsSuccess(true);
                setIsCompleted(true);
            } else {
                setTimeout(()=> {
                    checkTransaction(web3, txId);
                }, 3000)
            }
        })
    }

    return(
        <BModal
            fullscreen
            show={props.show}
            onHide={()=> props.onHide()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            // contentClassName={props.themeMode === "dark" ? "bg-black-modal": ""}
            contentClassName={props.themeMode === "dark" ? "semi-black-backdrop": "semi-black-backdrop"}
            // dialogClassName="semi-black-backdrop"
        > 
            <div className='d-flex align-items-center justify-content-center' style={{height: "100%"}}>
                <div className={props.themeMode === "dark" ? "bg-black-modal": "bg-black-modal"} style={{width: "300px", padding: "40px", borderRadius: "28px"}}>
                   
                <div className='modal-header h5'>
                    <div className='text-light-2'>Permission Request</div>
                </div>
                {loadingAllowance ? <Spinner animation="grow" vairant="secondary"/> : <div>
                    {isLoading && <div className='text-center'>
                        <Spinner variant='secondary' animation='grow'/>
                    </div>}
                    {txId !== null && <div className='text-center'>
                        <div className="text-light-1">Transaction Id</div>
                        <div className="mt-1 transaction-hash text-break">
                            <a className="text-light-2"
                                target=" _blank"
                                href={`${props.activeChain.get("webURL")}/tx/${txId}`}
                            >
                                <small>{shortAddress(txId)}<i className="fa fa-up-right-from-square ms-1"/></small> 
                            </a>
                        </div>
                    </div>}
                    {isCompleted === true && <div className="text-center mt-4">
                        <i className={`fa fa-2x fa-circle-${isSuccess === true ? "check text-success" : "xmark text-danger"}`}/>
                        <div className="fw-bold text-light-2 mt-2">{isSuccess ? <small>Approved Successfully</small> : <small>Transaction Failed</small>}</div>
                        <hr/>
                        <div className='mt-2'>
                            <div className="d-flex mb-4">
                            {isSuccess && <Button className="me-3"  onClick={()=> props.onSuccess()}>
                                Proceed to {props.desc}
                            </Button>}
                            </div>
                            <div>
                                <Button variant='outline-secondary' onClick={()=> props.onHide()}>Cancel</Button>
                            </div>
                        </div>
                    </div>}
                    {!isLoading  && !isCompleted && <div className='mb-2'>
                        <div className='text-light-1 mb-4'>Give permission to access your {fixed2Decimals(props.amount, props.token.get("decimals"))} {props.token.get("symbol")}?</div>
                        <Button onClick={()=> approve()} className="me-2">Confirm</Button>    
                        <Button variant='outline-secondary' onClick={()=> props.onHide()}>Cancel</Button> 
                    </div>}
                </div>}
            </div>
            </div> 
        </BModal>

    ) 
}

const mapStateToProps = state => {
   return {
       themeMode: state.get("themeMode"),
       web3: state.get("web3"),
       accAddress: state.get("accAddress"),
       activeChain: state.get("supportedChains").find((chain)=> chain.get("chainId") === state.get("chainId")),
       gasType: state.getIn(["gasPrices", "active"])
   }
}   

export default connect(mapStateToProps, {})(ApprovalModal);